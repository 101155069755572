import React, { useEffect, useState } from 'react';
import { commonService } from '_services/common.service';
import { Row, Col, Card, Button } from "reactstrap";
import { Link } from "react-router-dom";

const Newssection = () => {
    const [state, setState] = useState({
        news: []
    });

    useEffect(() => {
        getNewsdata();
    }, []);
    const getNewsdata = async () => {
        let news = await commonService.getServices('/publicChannel/news').catch(err => console.log('err', err));

        if (news) {
            //console.log("news:", news);
            state.news = news.data.items?news.data.items?.slice(0, 6):[];
            setState({ ...state });
        }
    }
    return (
        <Card className='m-0'>
            <h1 className='text-left'>Channel news & market insights</h1>
            <div className="p-3" style={{ backgroundColor: "#f8f9fe" }} id='dashslide'>

                <Row className="d-flex justify-content-center">
                    {state.news.map(x =>
                        <Col xl="4" lg="4" md="6" sm="12" xs="12">
                            <a href={`https://www.channel-summit.com/post/${x.slug}`} target='_blank'>
                                <Card >
                                    <div className='news-img'>
                                    <img alt="..." src={"https://static.wixstatic.com/media/" + x.coverImage.split("/")[3]}  />
                                    </div>
                                    <div class="justify-content-center">
                                        <div class="card-profile-image">
                                            {/* <a href={`https://www.channel-summit.com/post/${x.slug}`} target='_blank'>
                        <img alt="..." class="rounded-circle" src={x.author.profilePhoto} width={90} height={90}/>
                    </a> */}
                                        </div>

                                        {/* <div class="text-center border-0 pt-8 pt-md-2 pb-0 pb-md-2 card-header"> */}
                                            {/* <div class="d-flex justify-content-between">
                        <a href="#pablo"><span className='mt-3'>{x.author.nickname}</span></a>
                        <a href="#pablo"><span>{new Date(x.publishedDate).toString().substring(3, 15) + " - " + x.timeToRead + "min"}</span></a>
                    </div> */}
                                        {/* </div> */}

                                        <div class="pt-0 card-body chge-hov">
                                            <h2 className='Nwsovr text-left'>{x.title + " by " + x.author.nickname}</h2>
                                            <div class="text-center">
                                                <p className='Nwsovrp text-left'>{x.plainContent}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </a>
                        </Col>
                    )}
                </Row>
            </div>
            <div className='text-center pt-4'>
                <Link to="/newsList">
                    <button className="btn btn-primary btn-md ml-2"> More News</button>
                </Link>
            </div>
        </Card>
    );
};

export default Newssection;