import React, { useState, useEffect } from "react";
import {
  Card, CardBody, CardHeader, Container, FormGroup, Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup, Col, Row, UncontrolledTooltip
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import ReactQuill, { Quill } from "react-quill";
import { QuillEditor, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormTextArea, FormAutocompleteGrouping, FormAutocompleteGroupingRegion, AddFieldWithPopUpAutocomplete, FormRadio, NewFieldAutocomplete } from "_components/FormElements/FormInput";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";

import AnnualTurnOver from "views/Hoc/annualTurnOver";

import { BrandCard, ProfileCard } from "views/Hoc/BrandCard";
import { PdfCard, ImageCard } from "views/Hoc/PdfCard";
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { companyDistProfileSchema } from '../validation';
import { InputLabel, Select, TextField, FormControl, InputAdornment } from '@material-ui/core';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import { useBeforeFirstRender } from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import { ModalSpinner, Spinner } from 'views/Hoc/Spinner';
import MultiUpload from "views/Hoc/MultiUpload";
import { commonService } from '_services/common.service';
import { ImageLibraryMultipleUploads } from 'views/Hoc/Dialog/ImageLibraryMultipleUploads';
import { MultiSelectImages } from 'views/Hoc/Dialog/MultiSelectImages';
import VideoPreview from 'views/Hoc/Dialog/VideoPreview';
import { BannerUploader } from 'views/Hoc/ProfileUpload';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Badge } from '@material-ui/core';
import { DocumentName } from 'views/Hoc/Dialog/DocumentName';
import AddKeyPartner from "views/Hoc/Dialog/AddKeyPartner";
import SimpleHeader from "components/Headers/SimpleHeader";
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
import bannerPic from '../../../../assets/img/channelDetail/Default Banner.png';

var _ = require('lodash');


const CompanyProfileDist = (props) => {

  const [prodImage, setProdImage] = useState(false);
  let [comImage, setComImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch, setValue, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(companyDistProfileSchema),

  });
  console.log("errors", errors);
  const [brandName, setBrandName] = useState('');
  const [openadd, openAddnew] = useState(false);

  const [openRetailer, setOpenRetailer] = useState(false);
  const [openReseller, setOpenReseller] = useState(false);

  const [initLoad, setInitLoad] = useState(true);
  const [initialVideo, setInitialVideo] = useState('');
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [bannerId, setBannerId] = useState('');
  const [isAnnualTurn, setAnnualTurn] = useState(false);

  let [namePopup, setNamePopup] = useState(false);
  let [docName, setDocName] = useState(null);
  let [docId, setDocId] = useState(null);
  let [awardImage, setAwardImage] = useState(false);
  let [state, setState] = useState({
    yearOfEstablishment: '',
    companyRegNumber: '',
    companyLogo: null,
    companyLogoUrl: null,
    tweetPitch: '',
    introVideo: null,
    documentId: '',
    sourcing: '',
    companyDesc: "",
    awards: [],
    savedAwards: [],
    companyName: '',
    legalAddress: '',
    country: '',
    countryList: [],
    zipCode: '',
    city: '',
    phoneNumber1: '',
    phoneNumber2: '',
    mobile: '',
    email: '',
    // websiteUrl: '',
    linkedIn: '',
    insta: '',
    facebook: '',
    twitter: '',
    linkedIn_pre: 'https://www.linkedin.com/company/',
    insta_pre: 'https://www.instagram.com/',
    facebook_pre: 'https://www.facebook.com/',
    twitter_pre: 'https://twitter.com/',
    tradeUrls: '',
    cerificateName: '',
    isVat: '',
    regAddressId: '',
    billAddressId: '',
    b2bList: [{ id: Math.random(), value: '' }],
    deleted_B2B: [],
    websiteUrl: [{ id: Math.random(), value: '' }],
    deletedUrl: [],
    certificateId: '',
    vatId: '',
    vatNumberCheck: '',
    vatNumber: '',
    vat_verified: 0,
    social_linkedin_id: null,
    social_instagram_id: null,
    social_twitter_id: null,
    social_facebook_id: null,
    deletedAwards: [],
    deletedDocuments: [],
    brand: [],
    BrandData: [],
    brandList: [],
    brandOption: [],
    brandListUser: [], //dont worry about it. it's dummy but dont remove it.,
    productValue: [{ childid: "4", childname: "AV accessories", groupid: "1", groupname: "AV", status: true }],
    productFamilies: [],
    no_of_skus: null,
    storeCount: null,
    domesticRevenue: null,
    internationalRev: null,
    no_of_staffs: '',
    annualTurnOver: '',
    annualTurnOverList: [],
    deleted_companyImages: [],
    certifications: null,
    keyTags: [],
    deletedTags: [],

    isOEM: '3',

    DistributorProfileOption: [],
    DistributorProfileValue: [],

    speciality: [],
    specialityOptions: [],

    listOfServicesOption: [],
    listOfServiceValue: [],

    sellingCountries: [],
    sellingCountriesOption: [],
    targetCountries: [],
    targetCountriesOption: [],

    //keyretailer Popup
    no_of_retailers: null,
    RetailerDesc: '',
    ChannelProfileValue: [{ childid: "3", childname: "broadliner", status: true }],
    ChannelProfileOption: [],

    Retailer: [],
    RetailerList: [],
    RetailerOptions: [],
    testRetailerList: [],
    deletedRetailer: [],
    RetailerListUser: [],
    KeyRetData: [],

    //keyreseller popup
    no_of_resellers: null,
    ResellerDesc: '',
    ResellerProfileValue: [],
    ResellerProfileOption: [],

    Reseller: [],
    ResellerList: [],
    ResellerOptions: [],
    testResellerList: [],
    deletedReseller: [],
    ResellerListUser: [],
    KeyResData: [],

    areYouStartUp: '1',
    startUpType: []

  })

  console.log('reactqui', state.companyDesc.replace(/<[^>]+>/g, ''));

  const [previlege, setPrevilege] = useState(null);
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null, cid: null, JctId: null });

  //Multiupload
  const [mediaState, setMediaState] = useState({
    productBrochures: [],
    bannerImages: [],
    companyImages: []
  });

  //console.log("comstate:",state);

  const watchAll = watch(['companyName', 'CRN', 'VATN', 'websiteUrl', 'city', 'insta', 'linkedIn', 'facebook', 'twitter', 'zipCode', 'yearOfEstablishment', 'phoneNumber1', 'phoneNumber2', 'mobile', 'email', 'tradeUrls', 'no_of_skus', 'storeCount', 'startup', 'turnOver', 'staffCnt']);//'tradeUrls',


  //localStorage
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  const [businessType, setBusinessType] = useState(userData?.businessType);

  useEffect(() => {
    getCompanyData();
    (async () => {
      await setTradelocation();
    })();
    getAccessPrevilege();
  }, [])




  const [opneadd, opneAddnew] = useState(false);

  const getAccessPrevilege = () => {
    (async () => {
      await axios.get('user/userActionPrevilege/' + 2).then(res => {
        setPrevilege(res.data.length > 0 ? res.data[0] : 0);
      }).catch(err => {
        //ToastsStore.error('No Access Found!');
      });
    })();
  }

  const getCompanyDataOld = async () => {
    const apiUrl = '/setting/getCompanyDataOld';

    await axios.get(apiUrl).then(async res => {
      console.log('response', res.data);

      if (res.data) {
        state.brandList = res.data.brand ? res.data.brand : state.brandList;
        state.brandList.map(x => {
          state.brandOption = state.brandOption.filter(y => y.id != x.id);
        })

        state.companyLogo = res.data.channelLogo ? res.data.channelLogo : state.companyLogo;
        state.documentId = res.data.documentId ? res.data.documentId : state.documentId;
        state.introVideo = res.data.introvideoId ? res.data.introvideoId : state.introVideo;

        state.isOEM = res.data.isOEM ? res.data.isOEM : state.isOEM;

        if (res.data.banner) {
          setImageSrc(res.data.banner);
          setBannerId(res.data.channelBannerId);
          setMediaState({ ...mediaState, bannerImages: [{ image: res.data.banner, id: res.data.channelBannerId }] });
        }

        if (res.data.introVideo)
          setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.introVideo)
        state.yearOfEstablishment = res.data.estYear ? res.data.estYear : state.yearOfEstablishment;


        state.storeCount = res.data.storeCount ? res.data.storeCount : state.storeCount;
        state.no_of_skus = res.data.no_of_skus ? res.data.no_of_skus : state.no_of_skus;
        state.areYouStartUp = res.data.startup ? res.data.startup : state.areYouStartUp;

        state.startUpType = startUp.map(x => {
          if (res.data.maturitylevel?.filter(y => y == x.value).length)
            return x;
        });

        state.startUpType = state.startUpType.filter(x => x);

        state.domesticRevenue = res.data.revD ? res.data.revD : state.domesticRevenue;
        state.internationalRev = res.data.revI ? res.data.revI : state.internationalRev;
        state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange ? res.data.empCntRange.replace(/\s/g, "").trim() : state.no_of_staffs;
        if (res.data.turnOverList.length > 0) {
          res.data.turnOverList.sort((a, b) => a.year - b.year);

          state.annualTurnOver = res.data.turnOverList[0].turn_over;
        }
        else
          state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange ? res.data.turnoverRange.replace(/\s/g, "").trim() : state.annualTurnOver;

        if (res.data?.channelSocial?.length) {
          res.data.channelSocial.map(x => {
            if (x.socialSite.socialSiteId == 1) {
              state.social_linkedin_id = x.channelSocialLinkId;
              state.linkedIn = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
            if (x.socialSite.socialSiteId == 3) {
              state.social_instagram_id = x.channelSocialLinkId;
              state.insta = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }

            if (x.socialSite.socialSiteId == 4) {
              state.social_twitter_id = x.channelSocialLinkId;
              state.twitter = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }
            if (x.socialSite.socialSiteId == 5) {
              state.social_facebook_id = x.channelSocialLinkId;
              state.facebook = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
            }

            if (x.socialSite.socialSiteId == 2)
              state.tradeUrls = x.channelSocialLink
          })
        }

        state.tweetPitch = state.tweetPitch ? state.tweetPitch : res.data.detailDesc ? res.data.detailDesc.replace(/<[^>]+>/g, '') : state.tweetPitch;

        state.companyDesc = res.data.companyDesc ? res.data.companyDesc.replace(/<[^>]+>/g, '') : state.companyDesc;
        state.companyDesc1 = res.data.companyDesc1 ? res.data.companyDesc1.replace(/<[^>]+>/g, '') : state.companyDesc1;

        state.speciality = res.data.channelSpeciality.map(x => ({ id: x.productGroup.id, name: x.productGroup.name }));
        state.frenchTech = res.data.isFrenchTech == '1' ? 'Yes' : 'No';
        if (res.data.profileType.length) {
          state.DistributorProfileValue = Object.assign(res.data.profileType[0], { firstLetter: res.data.profileType[0].groupname });
          state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
            if (x.childid == state.DistributorProfileValue.childid)
              return { ...x, status: true }
            else
              return x;
          })
        }

        let reseller = res.data.profile?.filter(x => x.groupname == 'Reseller');
        let retailer = res.data.profile?.filter(x => x.groupname == 'Retailer');

        state.ChannelProfileValue = state.ChannelProfileValue ? state.ChannelProfileValue : retailer;
        state.ResellerProfileValue = state.ResellerProfileValue ? state.ResellerProfileValue : reseller;
        state.profileTypeId = +res.data.profileTypeId;


        state.no_of_resellers = state.no_of_resellers ? state.no_of_resellers : res.data.no_of_resellers;
        state.no_of_retailers = state.no_of_retailers ? state.no_of_retailers : res.data.no_of_retailers;

        state.RetailerDesc = res.data.keyretailers;
        state.ResellerDesc = res.data.keyresellers;

        await axios.get('/setting/oldKeyPartnerOptions/' + userData.channelTypeId + '/' + res.data.oldChannelId).then(res => {
          state.RetailerList = res.data.channelKeyRetailer;
          state.ResellerList = res.data.channelKeyReseller;
          state.DistributorList = res.data.channelKeyDistributor;

        });



        setState({ ...state });
      }
    })


    setInitLoad(false);
  }

  // const getCompanyData = async () => {

  //   const countryUrl = '/common/getSignup/' + userData.channelTypeId;
  //   const apiUrl = '/setting/company';




  //   await axios.get(apiUrl).then(res => {

  //     state.companyLogo = res.data.channel.channelDetail.channelLogo?.documentPath;
  //     state.companyName = res.data.channel.companyName;
  //     state.documentId = res.data.channel.channelDetail.channelLogo?.documentId;
  //     state.introVideo = res.data.channel.channelDetail.welcomevideo?.documentId;
  //     if (res.data.channel.channelDetail.welcomevideo)
  //       setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.channel.channelDetail.welcomevideo.documentUrl)
  //     state.yearOfEstablishment = res.data.channel.channelDetail.estYear;
  //     state.legalAddress = res.data.channel.regAddress.address;
  //     state.regAddressId = res.data.channel.regAddressId;
  //     state.billAddressId = res.data.channel.billAddressId;
  //     //setValue('city', res.data.channel.regAddress.city)
  //     //setValue('zipCode', res.data.channel.regAddress.postalCode)       
  //     state.zipCode = res.data.channel.regAddress.postalCode;
  //     state.city = res.data.channel.regAddress.city;
  //     state.isOEM = res.data.channel.isOEM ? res.data.channel.isOEM : state.isOEM;
  //     setBusinessType(state.isOEM);
  //     if (res.data.channel.channelCertificate) {
  //       res.data.channel.channelCertificate.map(x => {
  //         if (x.cerificateTypeId == '1') {
  //           state.companyRegNumber = x.cerificateNumber;
  //           state.certificateName = x.cerificateName;
  //           state.certificateId = x.channelCerificateId;
  //         }
  //         if (x.cerificateTypeId == '2') {
  //           state.vatNumber = x.cerificateNumber;
  //           state.vatNumberCheck = x.cerificateNumber;
  //           state.vat_verified = x.vat_verified;
  //           state.vatId = x.channelCerificateId;
  //         }
  //       })
  //     }

  //     if (props.ratingId || !state.certificateName) {
  //       state.certificateName = state.company_regid;
  //     }

  //     mediaState.productBrochures = [];
  //     if (res.data.channelPdf?.length) {
  //       res.data.channelPdf.map(x => {
  //         mediaState.productBrochures.push(x);
  //       })
  //     }
  //     mediaState.companyImages = [];
  //     if (res.data.channelImages?.length) {
  //       res.data.channelImages.map(x => {
  //         mediaState.companyImages.push(x);
  //       })
  //     }

  //     // state.vatNumber=res.data.channel.channelCertificate[1].cerificateNumber;

  //     state.phoneNumber1 = res.data.channel.channelDetail.phone1 ? res.data.channel.channelDetail.phone1 : ''; //.substring(res.data.channel.channelDetail.phone1.indexOf('-')+1,res.data.channel.channelDetail.phone1.length);
  //     state.phoneNumber2 = res.data.channel.channelDetail.phone2 ? res.data.channel.channelDetail.phone2 : '';
  //     state.mobile = res.data.channel.channelDetail.mobileNo;
  //     if (res.data.channel.channelDetail.webSiteUrl) {
  //       state.websiteUrl = res.data.channel.channelDetail.webSiteUrl.substring(0, 2) == '//' ? res.data.channel.channelDetail.webSiteUrl.substring(2, res.data.channel.channelDetail.webSiteUrl.length) : res.data.channel.channelDetail.webSiteUrl;
  //     }

  //     state.email = res.data.channel.companyMail;
  //     state.awards = [];
  //     state.savedAwards = [];
  //     if (res.data.channel.channelAward) {
  //       res.data.channel.channelAward.map(x => {
  //         if (x.documentId) {
  //           x.documentUrl = process.env.REACT_APP_CDN_URL + x.awardImg;
  //           state.awards.push(x);
  //           state.savedAwards.push(x);
  //         }

  //       })
  //     }
  //     // if(state.awards.length>0){
  //     //   state.awards.map(x=> state.savedAwards.push({documentId:x.documentId, id:x.id}));
  //     // }
  //     if (res.data.channel?.channelSocial?.length) {
  //       res.data.channel.channelSocial.map(x => {
  //         if (x.socialSite.socialSiteId == 1) {
  //           state.social_linkedin_id = x.channelSocialLinkId;
  //           state.linkedIn = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
  //         }
  //         if (x.socialSite.socialSiteId == 3) {
  //           state.social_instagram_id = x.channelSocialLinkId;
  //           state.insta = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
  //         }
  //         if (x.socialSite.socialSiteId == 4) {
  //           state.social_twitter_id = x.channelSocialLinkId;
  //           state.twitter = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
  //         }
  //         if (x.socialSite.socialSiteId == 5) {
  //           state.social_facebook_id = x.channelSocialLinkId;
  //           state.facebook = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
  //         }

  //         if (x.socialSite.socialSiteId == 2)
  //           state.tradeUrls = x.channelSocialLink


  //       })
  //     }

  //     if (res.data.channel?.channelB2B?.length) {
  //       state.b2bList = (res.data.channel.channelB2B.map(x =>
  //         ({ channel_B2B_id: x.channelB2BId, value: x.channelB2B })
  //       ))
  //     }
  //     else {
  //       state.b2bList = [{ id: Math.random(), value: '' }];
  //     }

  //     state.tweetPitch = res.data.channel.channelDetail.detailDesc ? res.data.channel.channelDetail.detailDesc?.replace(/<[^>]+>/g, '') : '';
  //     state.sourcing = res.data.channel.channelDetail.expectations ? res.data.channel.channelDetail.expectations : '';
  //     state.companyDesc = res.data.channel.channelDetail.companyDesc ? res.data.channel.channelDetail.companyDesc : '';
  //     state.country = { name: res.data.channel.country, id: res.data.channel.countryId };
  //     // state.insta=res.data.channel.channelSocial[0].channelSocialLink;
  //     // state.linkedIn=res.data.channel.channelSocial[1].channelSocialLink;

  //     if (res.data.linkedInBanner) {
  //       setImageSrc(res.data.linkedInBanner);
  //       setBannerId('dummy'); //we need this to use permanent cdn url
  //     }

  //     if (res.data.bannerImg?.documentPath) {
  //       setImageSrc(res.data.bannerImg?.documentPath);
  //       setBannerId(res.data.bannerImg?.documentId);
  //       setMediaState({ ...mediaState, bannerImages: [{ image: res.data.bannerImg?.documentPath, id: res.data.bannerImg?.documentId }] });
  //     }
  //     setState({ ...state });
  //     //setState({...state, countryList:res.data.country});
  //   }).catch(err => {
  //     commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/company' }, '/error')
  //     setInitLoad(false)
  //   });   


  //   await axios.get('/setting/getInitialProfile').then(async res => {

  //     state.company_regid = res.data.country[0].registrationcertificate;
  //     if (!state.certificateName)
  //       state.certificateName = res.data.country[0].registrationcertificate;
  //     state.isVat = res.data.country[0].isVat;
  //     state.BrandData = res.data.productBrand;
  //     state.brandOption = res.data.productBrand;
  //     state.productFamilies = res.data.productGroup;
  //     state.brandOption.push({ name: 'Add Brand', id: 0, doc_id: '0', documentpath: 'https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg' })

  //     let key = ['Retailer'];
  //     state.ChannelProfileOption = res.data.profileList.filter(i => key.includes(i.groupname));

  //     let key1 = ['Reseller'];
  //     state.ResellerProfileOption = res.data.profileList.filter(i => key1.includes(i.groupname));

  //     let key2 = ['Distributor'];
  //     state.DistributorProfileOption = res.data.distProfile.filter(i => key2.includes(i.groupname));

  //     setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
  //     setState({ ...state, ResellerProfileOption: state.ResellerProfileOption });


  //     await axios.get('/setting/getChannelBrand').then(res => {
  //       state.brandList = res.data.brand;


  //       state.brandList.map(x => {
  //         state.brandOption = state.brandOption.filter(y => y.id != x.id);
  //       })

  //     }).catch(err => {
  //       commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelBrand' }, '/error')
  //     });

  //     await axios.get('/setting/getCertificateTag').then(res => {
  //       if (res.data) {
  //         state.keyTags = res.data.map(x => {
  //           let temp = {};
  //           temp.certificatetagid = x.certificatetagid;
  //           temp.tag = x.tag;
  //           return temp;
  //         });
  //         setState({ ...state });
  //       }
  //     });
  //     //setState({...state, brandOption:state.brandOption});
  //   }).catch(err => {
  //     commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getInitialProfile' }, '/error')
  //   })

  //   await axios.get('/setting/getChannelDetail').then(res => {
  //     state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange.replace(/\s/g, "").trim();
  //     state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange.replace(/\s/g, "").trim();
  //     state.no_of_retailers = res.data.no_of_retailers;
  //     state.no_of_resellers = res.data.no_of_resellers;
  //     state.RetailerDesc = res.data.keyretailers;
  //     state.ResellerDesc = res.data.keyresellers;
  //     state.areYouStartUp = res.data.startup;

  //     state.startUpType = startUp.map(x => {
  //       if (res.data.maturitylevel?.filter(y => y == x.value).length)
  //         return x;
  //     });

  //     state.startUpType = state.startUpType.filter(x => x);
  //     setState({ ...state });

  //   }).catch(err => {
  //     commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
  //   });

  //   await axios.get('/setting/getChannelProduct').then(res => {
  //     if (res.data.productGroup.length && state.productFamilies.length) {
  //       state.productFamilies = state.productFamilies.map(x => {
  //         res.data.productGroup.map(y => {
  //           if (x.groupid == y.groupid && x.childid == y.childid) {
  //             x.status = true;
  //           }
  //         })
  //         return x;
  //       })
  //     }

  //     state.productValue = res.data.productGroup;
  //     setState({ ...state, productFamilies: state.productFamilies, productValue: state.productValue });

  //   }).catch(err => {
  //     commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
  //   });

  //   await axios.get('/setting/keyPartnerOptions').then(res => {
  //     state.RetailerList = res.data.channelKeyRetailer;
  //     state.ResellerList = res.data.channelKeyReseller;
  //     state.KeyRetData = res.data.keyPartners?.keyRetailerOptions;
  //     state.RetailerOptions = res.data.keyPartners?.keyRetailerOptions;
  //     state.KeyResData = res.data.keyPartners?.keyResellerOptions;
  //     state.ResellerOptions = res.data.keyPartners?.keyResellerOptions;
  //     state.testRetailerList = res.data.channelKeyRetailer.map(x => x.id);
  //     state.testResellerList = res.data.channelKeyReseller.map(x => x.id);

  //     state.RetailerOptions = state.KeyRetData;
  //     state.RetailerList.map(x => {
  //       state.RetailerOptions = state.RetailerOptions.filter(y => y.id != x.id);
  //     })

  //     state.ResellerOptions = state.KeyResData;
  //     state.ResellerList.map(x => {
  //       state.ResellerOptions = state.ResellerOptions.filter(y => y.id != x.id);
  //     })

  //     setState({ ...state });
  //   });

  //   await axios.get(countryUrl).then(res => {
  //     state.countryList = res.data.country;
  //     setState({ ...state });
  //   }).catch(err => {
  //     commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
  //   });

  //   await axios.get('/setting/profileAndSpeciality').then(res => {
  //     state.specialityOptions = res.data.specialityOptions.map(x => ({ id: x.productGroupId, name: x.productGroup }));
  //     state.speciality = res.data.channelSpeciality.map(x => ({ id: x.productGroup.id, name: x.productGroup.name }));
  //     state.frenchTech = res.data.isFrenchTech == '1' ? 'Yes' : 'No';
  //     if (res.data.profileType.length) {
  //       state.DistributorProfileValue = Object.assign(res.data.profileType[0], { firstLetter: res.data.profileType[0].groupname });
  //       state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
  //         if (x.childid == state.DistributorProfileValue.childid)
  //           return { ...x, status: true }
  //         else
  //           return x;
  //       })
  //     }

  //   })

  //   await axios.get('/setting/getChannelProfile').then(res => {

  //     let reseller = res.data.profile?.filter(x => x.groupname == 'Reseller');
  //     let retailer = res.data.profile?.filter(x => x.groupname == 'Retailer');

  //     state.ChannelProfileValue = retailer;
  //     state.ResellerProfileValue = reseller;
  //     setState({
  //       ...state, ChannelProfileValue: state.ChannelProfileValue,
  //       ResellerProfileValue: state.ResellerProfileValue,
  //       profileTypeId: +res.data.profileTypeId,
  //     });

  //   }).catch(err => {
  //     commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
  //     setInitLoad(false);
  //     setLoading(false);
  //   })


  //   if (props.ratingId) {
  //     // await getCompanyDataOld();
  //   }

  //   setInitLoad(false);
  //   setLoading(false);
  // }

  const getCompanyData = async () => {

    const countryUrl = '/common/getSignup/' + userData.channelTypeId;
    const apiUrl = '/channelAdmin/company';
    let data = {
      channelId: +userData.channelId,
      userId: +userData.userId,
      channelTypeId: +userData.channelTypeId
    };

    await axios.post(apiUrl, data).then(res => {

      state.companyLogo = res.data.channel.channelDetail?.channelLogo?.documentPath;
      state.companyLogoUrl = res.data.channel.channelDetail.companyLogoUrl;
      state.companyName = res.data.channel.companyName;
      state.documentId = res.data.channel.channelDetail.channelLogo?.documentId;
      state.introVideo = res.data.channel.channelDetail.welcomevideo?.documentId;
      if (res.data.channel.channelDetail.welcomevideo)
        setInitialVideo(process.env.REACT_APP_CDN_URL + res.data.channel.channelDetail.welcomevideo.documentUrl)
      state.yearOfEstablishment = res.data.channel.channelDetail.estYear;

      state.legalAddress = res.data.channel.regAddress ? res.data.channel.regAddress?.address : '';
      state.regAddressId = res.data.channel.regAddressId;
      state.billAddressId = res.data.channel.billAddressId;
      //setValue('city', res.data.channel.regAddress.city)
      //setValue('zipCode', res.data.channel.regAddress.postalCode)       
      state.zipCode = res.data.channel.regAddress ? res.data.channel.regAddress?.postalCode : '';
      state.city = res.data.channel.regAddress ? res.data.channel.regAddress?.city : '';
      state.isOEM = res.data.channel.isOEM ? res.data.channel.isOEM : state.isOEM;
      setBusinessType(state.isOEM);
      if (res.data.channel.channelCertificate) {
        res.data.channel.channelCertificate.map(x => {
          if (x.cerificateTypeId == '1') {
            state.companyRegNumber = x.cerificateNumber;
            // state.certificateName = x.cerificateName;
            state.certificateId = x.channelCerificateId;
          }
          if (x.cerificateTypeId == '2') {
            state.vatNumber = x.cerificateNumber;
            state.vatNumberCheck = x.cerificateNumber;
            state.vat_verified = x.vat_verified;
            state.vatId = x.channelCerificateId;
          }
        })
      }

      if (res.data.channel.signUpStatusId == 14 || !state.cerificateName) {
        state.certificateName = state.company_regid;
      }

      mediaState.productBrochures = [];
      if (res.data.channelPdf?.length) {
        res.data.channelPdf.map(x => {
          mediaState.productBrochures.push(x);
        })
      }
      mediaState.companyImages = [];
      if (res.data.channelImages?.length) {
        res.data.channelImages.map(x => {
          mediaState.companyImages.push(x);
        })
      }

      // state.vatNumber=res.data.channel.channelCertificate[1].cerificateNumber;

      state.phoneNumber1 = res.data.channel.channelDetail.phone1 ? res.data.channel.channelDetail.phone1 : ''; //.substring(res.data.channel.channelDetail.phone1.indexOf('-')+1,res.data.channel.channelDetail.phone1.length);
      state.phoneNumber2 = res.data.channel.channelDetail.phone2 ? res.data.channel.channelDetail.phone2 : '';
      state.mobile = res.data.channel.channelDetail.mobileNo;
      if (res.data.channel.channelDetail.webSiteUrl)
        state.websiteUrl = res.data.channel.channelDetail.webSiteUrl.substring(0, 2) == '//' ? res.data.channel.channelDetail.webSiteUrl.substring(2, res.data.channel.channelDetail.webSiteUrl.length) : res.data.channel.channelDetail.webSiteUrl;
      state.email = res.data.channel.companyMail;

      state.awards = [];
      state.savedAwards = [];
      if (res.data.channel.channelAward) {
        res.data.channel.channelAward.map(x => {
          if (x.documentId) {
            x.documentUrl = process.env.REACT_APP_CDN_URL + x.awardImg;
            state.awards.push(x);
            state.savedAwards.push(x);
          }

        })
      }

      // state.awards=res.data.channel.channelAward?res.data.channel.channelAward.map(x => {
      //   if(x.documentId)
      //       x.documentUrl = process.env.REACT_APP_CDN_URL+x.awardImg;

      //       return x;
      // }):[];


      if (res.data.channel?.channelSocial?.length) {
        res.data.channel.channelSocial.map(x => {
          if (x.socialSite.socialSiteId == 1) {
            state.social_linkedin_id = x.channelSocialLinkId;
            state.linkedIn = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }
          if (x.socialSite.socialSiteId == 3) {
            state.social_instagram_id = x.channelSocialLinkId;
            state.insta = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }
          if (x.socialSite.socialSiteId == 4) {
            state.social_twitter_id = x.channelSocialLinkId;
            state.twitter = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }
          if (x.socialSite.socialSiteId == 5) {
            state.social_facebook_id = x.channelSocialLinkId;
            state.facebook = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
          }

          if (x.socialSite.socialSiteId == 2)
            state.tradeUrls = x.channelSocialLink


        })
      }

      if (res.data.channel?.channelB2B?.length) {
        state.b2bList = (res.data.channel.channelB2B.map(x =>
          ({ channel_B2B_id: x.channelB2BId, value: x.channelB2B })
        ))
      }
      else {
        state.b2bList = [{ id: Math.random(), value: '' }];
      }

      state.tweetPitch = res.data.channel.channelDetail.detailDesc ? res.data.channel.channelDetail.detailDesc?.replace(/<[^>]+>/g, '') : '';
      state.sourcing = res.data.channel.channelDetail.expectations ? res.data.channel.channelDetail.expectations : '';
      state.companyDesc = res.data.channel.channelDetail.companyDesc ? res.data.channel.channelDetail.companyDesc : '';
      state.country = { name: res.data.channel.country, id: res.data.channel.countryId };
      // state.insta=res.data.channel.channelSocial[0].channelSocialLink;
      // state.linkedIn=res.data.channel.channelSocial[1].channelSocialLink;

      if (res.data.linkedInBanner) {
        setImageSrc(res.data.linkedInBanner);
        setBannerId('dummy'); //we need this to use permanent cdn url
      }

      if (res.data.bannerImg?.documentPath) {
        setImageSrc(res.data.bannerImg?.documentPath);
        setBannerId(res.data.bannerImg?.documentId);
        setMediaState({ ...mediaState, bannerImages: [{ image: res.data.bannerImg?.documentPath, id: res.data.bannerImg?.documentId }] });
      }
      setState({ ...state });
      //setState({...state, countryList:res.data.country});
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/company' }, '/error')
      setInitLoad(false)
    });

    await axios.post('/channelAdmin/getInitialProfile', data).then(async res => {

      state.company_regid = res.data.country[0].registrationcertificate;
      if (!state.certificateName)
        state.certificateName = res.data.country[0].registrationcertificate;
      state.isVat = res.data.country[0].isVat;
      state.BrandData = res.data.productBrand;
      state.brandOption = res.data.productBrand;
      state.productFamilies = res.data.productGroup;
      state.brandOption.push({ name: 'Add Brand', id: 0, doc_id: '0', documentpath: 'https://d35w8j22j5uly8.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg' })

      let key = ['Retailer'];
      state.ChannelProfileOption = res.data.profileList.filter(i => key.includes(i.groupname));

      let key1 = ['Reseller'];
      state.ResellerProfileOption = res.data.profileList.filter(i => key1.includes(i.groupname));

      let key2 = ['Distributor'];
      state.DistributorProfileOption = res.data.distProfile.filter(i => key2.includes(i.groupname));

      setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
      setState({ ...state, ResellerProfileOption: state.ResellerProfileOption });

      await axios.post('/channelAdmin/getChannelDetail', data).then(res => {
        state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange?.replace(/\s/g, "").trim();

        if (res.data.turnoverRange)
          state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange ? res.data.turnoverRange.replace(/\s/g, "").trim() : state.annualTurnOver;
        else if (res.data.turnOverList.length > 0) {
          res.data.turnOverList.sort((a, b) => b.year - a.year);
          console.log("aturns:", res.data.turnOverList, res.data.turnOverList[0].year)
          state.annualTurnOver = res.data.turnOverList.filter(at => at.turn_over != '')[0].turn_over;
        }


        state.no_of_retailers = res.data.no_of_retailers;
        state.no_of_resellers = res.data.no_of_resellers;
        state.RetailerDesc = res.data.keyretailers;
        state.ResellerDesc = res.data.keyresellers;

        state.areYouStartUp = res.data.startup;

        state.startUpType = startUp.map(x => {
          if (res.data.maturitylevel?.filter(y => y == x.value).length)
            return x;
        });

        state.startUpType = state.startUpType.filter(x => x);


      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
      });

      await axios.post('/channelAdmin/getCertificateTag', data).then(res => {
        if (res.data) {
          state.keyTags = res.data.map(x => {
            let temp = {};
            temp.certificatetagid = x.certificatetagid;
            temp.tag = x.tag;
            return temp;
          });
          setState({ ...state });
        }
      });
      //setState({...state, brandOption:state.brandOption});
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getInitialProfile' }, '/error')
    })


    await axios.post('/channelAdmin/getChannelProduct', data).then(res => {
      if (res.data.productGroup.length && state.productFamilies.length) {
        state.productFamilies = state.productFamilies.map(x => {
          res.data.productGroup.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid) {
              x.status = true;
            }
          })
          return x;
        })
      }

      state.productValue = res.data.productGroup;
      setState({ ...state, productFamilies: state.productFamilies, productValue: state.productValue });

    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
    });

    await axios.post('/channelAdmin/getChannelBrand', data).then(res => {
      state.brandList = res.data.brandList.filter(x => x.isDeleted == false);

      state.brandList.map(x => {
        state.brandOption = state.brandOption.filter(y => y.id != x.id);
      })

    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelBrand' }, '/error')
    });


    await axios.post('/channelAdmin/keyPartnerOptions', data).then(res => {
      state.RetailerList = res.data.channelKeyRetailer;
      state.ResellerList = res.data.channelKeyReseller;
      state.KeyRetData = res.data.keyPartners?.keyRetailerOptions;
      state.RetailerOptions = res.data.keyPartners?.keyRetailerOptions;
      state.KeyResData = res.data.keyPartners?.keyResellerOptions;
      state.ResellerOptions = res.data.keyPartners?.keyResellerOptions;
      state.testRetailerList = res.data.channelKeyRetailer.map(x => x.id);
      state.testResellerList = res.data.channelKeyReseller.map(x => x.id);


      state.RetailerOptions = state.KeyRetData;
      state.RetailerList.map(x => {
        state.RetailerOptions = state.RetailerOptions.filter(y => y.id != x.id);
      })

      state.ResellerOptions = state.KeyResData;
      state.ResellerList.map(x => {
        state.ResellerOptions = state.ResellerOptions.filter(y => y.id != x.id);
      })



      setState({ ...state });
    });

    await axios.get(countryUrl).then(res => {
      state.countryList = res.data.country;
      setState({ ...state });
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
    });

    await axios.post('/channelAdmin/profileAndSpeciality', data).then(res => {
      state.specialityOptions = res.data.specialityOptions.map(x => ({ id: x.productGroupId, name: x.productGroup }));
      state.speciality = res.data.channelSpeciality.map(x => ({ id: x.productGroup.id, name: x.productGroup.name }));
      state.frenchTech = res.data.isFrenchTech == '1' ? 'Yes' : 'No';
      if (res.data.profileType.length) {
        state.DistributorProfileValue = Object.assign(res.data.profileType[0], { firstLetter: res.data.profileType[0].groupname });
        state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
          if (x.childid == state.DistributorProfileValue.childid)
            return { ...x, status: true }
          else
            return x;
        })
      }

    })


    await axios.post('/channelAdmin/getChannelProfile', data).then(res => {

      let reseller = res.data.profile?.filter(x => x.groupname == 'Reseller');
      let retailer = res.data.profile?.filter(x => x.groupname == 'Retailer');

      state.ChannelProfileValue = retailer;
      state.ResellerProfileValue = reseller;
      setState({
        ...state, ChannelProfileValue: state.ChannelProfileValue,
        ResellerProfileValue: state.ResellerProfileValue,
        profileTypeId: +res.data.profileTypeId,
      });
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
      setInitLoad(false);
      setLoading(false);
    })


    // if (userData.signUpStatusId == 14) {
    //   await getCompanyDataOld();
    // }

    //    if (props.ratingId) {
    //   await getCompanyDataOld();
    // }

    setInitLoad(false);


    setLoading(false);
  }

  const pdfDelete = (data) => {
    let filteredState = mediaState.productBrochures.filter(item => {
      if (item.documentId == data.documentId && item.channelDocumentId) {
        let temp = state.deletedDocuments;
        temp.push(+item.channelDocumentId);
        setState({ ...state, deletedDocuments: temp });
      }
      return item.documentId != data.documentId
    });
    if (filteredState) {
      setMediaState({ ...mediaState, productBrochures: filteredState });
      console.log('after delete', mediaState);
    }
  }

  const setKeyOptions = (input, keyname) => {
    let temp = [];
    let tempoptions = state[keyname];
    state[input + 'List'].map(x => {
      tempoptions = tempoptions.filter(y => y.id != x.id);
    })

    setState({ ...state, [input + 'Options']: tempoptions });
  }

  const setKeyOptions1 = (input, keyname) => {

    let tempoptions = state[keyname];
    state[input + 'List'].map(x => {
      tempoptions = tempoptions.filter(y => y.id != x.id);
    })

    setState({ ...state, [input + 'Option']: tempoptions });
  }

  const deleteImg = (e) => {
    console.log('e', e);
    // error.productPos=null;
    mediaState.companyImages = mediaState.companyImages.filter(x => x.documentId !== e.documentId);
    if (e?.companyMediaId)
      state.deleted_companyImages.push(parseInt(e.companyMediaId));

    setMediaState({ ...mediaState });
    setState({ ...state });
    // setError({...error});
  }


  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    //console.log("check:",check,gname);
    if (check == true) {
      state[optionName].map((item) => {

        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    // if(optionValue='ChannelProfileValue' && state.ChannelProfileValue.length==0)
    //       errors.relevantChannelProfiles=true;
    // else 
    if (optionValue === 'sellingCountries' && state.sellingCountries.length === 0)
      errors.selling = true;
    if (optionValue = 'productValue' && state.productValue.length == 0)
      errors.productCat = true;
  }


  const openAdd = async (input, value) => {
    //console.log('opneadd',opneadd);
    await setBrandName(input);
    openAddnew(true);
  }

  const closeModalPopup = () => {
    setBrandName('');
    openAddnew(false);
    setOpenRetailer(false);
    setOpenReseller(false);
  }

  const introVideoId = async (data) => {
    console.log('introVideoId', data.documentId);
    setInitialVideo('');
    setState({ ...state, introVideo: data.documentId });
    setLoading(true)
    let res = await commonService.postService({ id: data.documentId }, '/setting/updateIntroVideo')
      .catch(err => {
        ToastsStore.error('Something Went Wrong!');
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/updateIntroVideo' }, '/error')
      });
    if (res) {
      ToastsStore.success('Intro video Saved Successfully!');
    }
    setLoading(false);
  }

  const getCompanyLogo = (resImagePath) => {
    setState({ ...state, companyLogo: resImagePath.document.documentPath, documentId: resImagePath.documentId });
  };

  const opneAdd1 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenRetailer(true);
  }

  const opneAdd2 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenReseller(true);
  }

  const deleteBrand = (id) => {
    const filteredList = state.brandList.filter((item) => item.id !== id);
    state.brandList = filteredList;
    setState({ ...state, brandList: state.brandList });
    if (state.brandList.length == 0) {
      setState({ ...state, brandValue: [] });
      errors.Brands = { message: 'Please select a Brand', type: 'required' };
    }
    setKeyOptions1('brand', 'BrandData');
  }

  const deleteBrand1 = (id) => {
    const filteredList = state.RetailerList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedRetailer;
        temp.push(+item.keypartnerid);
        setState({ ...state, deletedRetailer: temp });
      }
      if (item.id == id && item.isNew == '1') {
        state.RetailerListUser = state.RetailerListUser.filter(x => x.id != item.id);
      }
      return item.id !== id
    });
    state.RetailerList = filteredList;
    setState({ ...state, RetailerList: state.RetailerList, RetailerListUser: state.RetailerListUser });
    if (state.RetailerList.length == 0) {
      setState({ ...state, Retailer: [] });
      // errors.Retailers={message: 'Please select a Brand', type:'required'};  
    }
    setKeyOptions('Retailer', 'KeyRetData');
  }

  const deleteBrand2 = (id) => {
    const filteredList = state.ResellerList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedReseller;
        temp.push(+item.keypartnerid);
        setState({ ...state, deletedReseller: temp });
      }
      if (item.id == id && item.isNew == '1') {
        state.ResellerListUser = state.ResellerListUser.filter(x => x.id != item.id);
      }
      return item.id !== id
    });
    state.ResellerList = filteredList;
    setState({ ...state, ResellerList: state.ResellerList, ResellerListUser: state.ResellerListUser });
    if (state.ResellerList.length == 0) {
      setState({ ...state, Reseller: [] });
      // errors.Retailers={message: 'Please select a Brand', type:'required'};  
    }
    setKeyOptions('Reseller', 'KeyResData');
  }

  const save = async () => {
    if (userData.roleId == 1 || (userData.roleId != 1 && (previlege == 1 || previlege == 0)))
      saveCompDist();
    else {
      modalPopValue.message = 'You are restricted to Modify and Save Company Info';
      modalPopValue.second_message = 'PLEASE CONTACT ACCOUNT OWNER'
      modalPopValue.title = 'Access Denied';
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    }

  }

  const [companyError, setCompanyError] = useState(false);
  useEffect(() => {
    setCompanyError(false);
  }, [watchAll.companyName]);

  const saveCompDist = async () => {
    setLoading(true);
    try {


      let checkData = {
        companyName: state.companyName
      }

      let checkname = await axios.post('setting/checkCompanyName', checkData).catch(err => {
        ToastsStore.warning('Something went wrong');
      });

      if (!checkname.data.isAvailable) {
        setCompanyError(true);
        ToastsStore.warning('Company Name Already Taken.')
        setLoading(false);
        return;
      }

      //setting certificates
      let tax_cert = [];
      let reg_cert = [];
      reg_cert.push({ name: state.certificateName, id: state.companyRegNumber, channel_certificate_id: parseInt(state.certificateId) });
      if (state.isVat == '1') {

        // if(state.vatNumber != state.vatNumberCheck || state.vat_verified!='1'){
        // await axios.post('/setting/checkVat', {vatNumber: state.vatNumber}).then(res => {
        //   state.vat_verified = res.data.valid;
        // }).catch(err => console.log('checkvat error', err));
        // }

        if (!state.vatNumber)
          state.vat_verified = '0';

        tax_cert.push({ name: 'VAT', id: state.vatNumber, channel_certificate_id: parseInt(state.vatId), vat_verified: state.vat_verified == '1' ? '1' : '0' });
      }


      let certificates = {
        companyRegNumber: state.companyRegNumber,
        certificateName: state.certificateName,
        vatNumber: state.isVat == '1' ? state.vatNumber : null,
        registration_certificate: reg_cert,
        vat_verified: state.vat_verified == '1' ? '1' : '0'
      }

      if (tax_cert.length)
        certificates = Object.assign(certificates, { tax_certificate: tax_cert });



      //setting awards
      // let award_details=state.awards ? [].concat(state.awards.map(x => {
      //   if(x.isOld)
      //   {
      //     // let isLifeTime = x.lifeTime==='1' || x.lifeTime==true ? '1' : '0';
      //     // let valid_upto = x.date!=='1' ? x.date : null;
      //     // if(!valid_upto && isLifeTime == '0')
      //     //   valid_upto = x.date;
      //     return {channel_award_id : x.id,image: x.awardImg ? x.awardImg : null, documentId: x.documentId ? x.documentId : null, }
      //   }
      //     return { image: x.awardImg ? x.awardImg : null }
      // })) : [{}];
      let awardDocumentIds = [];
      console.log('savedAwards', state.savedAwards);

      if (state.savedAwards?.length)
        state.savedAwards.map(x => awardDocumentIds.push(x.documentId));

      let award_details = state.awards.filter(x => !awardDocumentIds.includes(x.documentId));
      let deleted_award_details = state.deletedAwards.length ? state.deletedAwards : [];

      let award = {
        award_details: award_details,
        deleted_award_details: deleted_award_details
      }

      //saving media files together
      //setting banner
      let bannerImage = mediaState?.bannerImages?.length ? [].concat(mediaState.bannerImages.map(x => {
        return { image: x.image, id: x.id ? x.id : '' }
      })) : [];//[{image:null}],

      //setting broucher
      let productBrochure = {
        productpdf: mediaState?.productBrochures?.length ? [].concat(mediaState.productBrochures.map(x => {
          return { channelDocumentId: x.channelDocumentId ? +x.channelDocumentId : null, documentId: +x.documentId, document: x.document.documentUrl, documentName: x.name };
        })) : [{ document: 'null' }]//{image:null,documentName:null,documentDescription:null}]
      }


      let media = {
        channel_document: productBrochure.productpdf,
        bannerImage: bannerImage,
        companyImages: mediaState.companyImages,  //setting company images
        deleted_companyImages: state.deleted_companyImages
      }

      if (media?.companyImages?.length === 0)
        delete media.companyImages

      if (media?.deleted_companyImages?.length === 0)
        delete media.deleted_companyImages

      if (state?.deletedDocuments?.length)
        media = Object.assign(media, { deleted_channel_documents: state.deletedDocuments });


      //setting productcategories
      let productFamilies = [];
      let uniq = _.uniq(state.productValue.map(x => +x.groupid));
      uniq.map(x => {
        productFamilies.push({ id: +x });
      })
      let productCategories = [];
      state.productValue.map(x => {
        productCategories.push({
          productFamilId: +x.groupid,
          productCategoriesId: +x.childid
        })
      })

      //key retailer and key seller

      //for key distributor
      let keyResellers = [];

      if (isEqual(state.ResellerList.map(x => x.id).sort(), state.testResellerList.sort())) {
        console.log('no change');
      } else {
        state.ResellerList.map(x => {
          let temp = {};
          if (x.active) {
            temp.keypartnerid = x.keypartnerid;
            temp.partnername = x.name;
            temp.existchannelid = x.id;
            temp.countryid = x.active.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
          } else if (x.old) {
            temp.keypartnerid = x.keypartnerid;
            temp.partnername = x.name;
            temp.oldchannelid = x.id;
            temp.countryid = x.old.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
          }
          else if (x.new) {
            temp.keypartnerid = x.keypartnerid;
            temp.partnername = x.name;
            temp.countryid = x.new.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
          }
          else if (x.channelkeypartnerid) {
            temp.channelkeypartnerid = x?.channelkeypartnerid;
            temp.countryid = x?.regaddresscountry?.countryId;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.companylogo.documentId : null;
          }
          else if (x.isNew != '1') {
            if (x.oldchannelid)
              temp.oldchannelid = x.oldchannelid;
            else if (x.channelId)
              temp.existchannelid = x.channelId;

            temp.partnername = x.companyName;
            temp.image = x.companylogo ? x.companylogo.documentPath : null;
            temp.imageid = x.companylogo ? x.companylogo.documentId : null;
            temp.countryid = x?.regaddresscountry?.countryId;

          }
          if (x.isNew == '1') {

            if (x.documentpath)
              temp.image = x.documentpath;

            temp.partnername = x.name;
            temp.countryid = +x.countryId;
          }
          keyResellers.push(temp);
        })
        //console.log('fasle');
      }

      //for key retailer

      let keyRetailer = [];

      if (isEqual(state.RetailerList.map(x => x.id).sort(), state.testRetailerList.sort())) {
        console.log('no change');
      } else {
        state.RetailerList.map(x => {
          let temp = {};
          if (x.active) {
            temp.keypartnerid = x.keypartnerid;
            temp.partnername = x.name;
            temp.existchannelid = x.id;
            temp.countryid = x.active.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
          } else if (x.old) {
            temp.keypartnerid = x.keypartnerid;
            temp.partnername = x.name;
            temp.oldchannelid = x.id;
            temp.countryid = x.old.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
          }
          else if (x.new) {
            temp.keypartnerid = x.keypartnerid;
            temp.partnername = x.name;
            temp.countryid = x.new.country.country_id;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
          }
          else if (x.channelkeypartnerid) {
            temp.channelkeypartnerid = x?.channelkeypartnerid;
            temp.countryid = x?.regaddresscountry?.countryId;
            temp.image = x.documentPath ? x.documentPath : null;
            temp.imageid = x.documentPath ? x.companylogo.documentId : null;
          }
          else if (x.isNew != '1') {
            if (x.oldchannelid)
              temp.oldchannelid = x.oldchannelid;
            else if (x.channelId)
              temp.existchannelid = x.channelId;

            temp.partnername = x.companyName;
            temp.image = x.companylogo ? x.companylogo.documentPath : null;
            temp.imageid = x.companylogo ? x.companylogo.documentId : null;
            temp.countryid = x?.regaddresscountry?.countryId;

          } if (x.isNew == '1') {
            // temp.countryid = x.countryId;
            if (x.documentpath)
              temp.image = x.documentpath;

            temp.partnername = x.name;
            temp.countryid = +x.countryId;
          }

          keyRetailer.push(temp);
        })
        console.log('fasle');
      }

      let keyPartners = {};


      //social profiles
      let socialProfile = {
        social_linkedin_url: state.linkedIn,
        social_instagram_url: state.insta,
        social_linkedin_id: state.social_linkedin_id,
        social_googleplus_id: null,
        social_instagram_id: state.social_instagram_id,
        contact_linkedin_id: null,
        social_twitter_url: state.twitter,
        social_twitter_id: state.social_twitter_id,
        social_facebook_url: state.facebook,
        social_facebook_id: state.social_facebook_id,
      };

      if (state.b2bList.length) {
        state.b2bList = [].concat(state.b2bList.map(x => {

          if (x.channel_B2B_id && x.value)
            return { B2B_url: x.value, channel_B2B_id: x.channel_B2B_id }
          else if (x.channel_B2B_id && !x.value) {
            state.deleted_B2B.push(x.channel_B2B_id);
            return null;
          } else if (x.value && !x.channel_B2B_id) {
            return { B2B_url: x.value }
          }

        }));
        state.b2bList = state.b2bList.filter((el) => el != null);
        socialProfile = Object.assign(socialProfile, { deleted_B2B: state.deleted_B2B.length ? [... new Set(state.deleted_B2B)] : [null] });
        if (state.b2bList.length)
          socialProfile = Object.assign(socialProfile, { B2B_url: state.b2bList });
      }

      let websiteUrl = {};
      //web urls
      //  if(state.websiteUrl.length){
      //   state.websiteUrl = [].concat(state.websiteUrl.map(x => {

      //      if(x.id && x.value)
      //        return {websiteUrl: x.value, id: x.id}
      //      else if(x.id && !x.value)
      //      {
      //        state.deletedUrl.push(x.id);
      //        return null;
      //      }else if(x.value && !x.id)
      //      {
      //        return {websiteUrl: x.value}
      //      }          

      //  }));
      //  state.websiteUrl = state.websiteUrl.filter((el)=> el!=null);
      //  websiteUrl = Object.assign(websiteUrl, {delWebUrl: state.deletedUrl.length ? [... new Set(state.deletedUrl)] : [null]});
      //  if(state.deletedUrl.length)
      //  websiteUrl = Object.assign(websiteUrl, {websiteUrl: state.websiteUrl});
      // }

      //setting profiles (relevant retailer and relevant reseller)
      let customerProfiles = {};

      //  if(state.isOEM=='2'){

      //    customerProfiles = [].concat(state.ResellerProfileValue.map(x => {return {profileTypeId:+x.childid,profileTypeClassificationId:+x.profiletypeclassificationid} }));


      //  }else if(state.isOEM=='1'){

      //    customerProfiles = [].concat(state.ChannelProfileValue.map(x => {return {profileTypeId:+x.childid,profileTypeClassificationId:+x.profiletypeclassificationid} }))



      //  }else if(state.isOEM=='3'){

      let tmp = [].concat(state.ChannelProfileValue.map(x => { return { profileTypeId: +x.childid, profileTypeClassificationId: +x.profiletypeclassificationid } }));
      let tmp1 = [].concat(state.ResellerProfileValue.map(x => { return { profileTypeId: +x.childid, profileTypeClassificationId: +x.profiletypeclassificationid } }));
      customerProfiles = [...tmp, ...tmp1];

      //  }

      //deleted retailer
      if (state.deletedRetailer.length) {
        if (state.RetailerList.length) {
          state.RetailerList.map(x => {
            if (x.keypartnerid) {
              let temp = state.deletedRetailer.filter(id => x.keypartnerid != id)
              setState({ ...state, deletedRetailer: temp });
            }
          })
        }
      }

      //deleted Reseller
      if (state.deletedReseller.length) {
        if (state.ResellerList.length) {
          state.ResellerList.map(x => {
            if (x.keypartnerid) {
              let temp = state.deletedReseller.filter(id => x.keypartnerid != id)
              setState({ ...state, deletedReseller: temp });
            }
          })
        }
      }

      let data = {};
      //key retailer value
      if (keyRetailer.length) {
        data = Object.assign(data, { channelKeyRetailer: keyRetailer })
      }

      //key distributor value
      if (keyResellers.length) {
        data = Object.assign(data, { channelKeyReseller: keyResellers })
      }

      //delete retailer value
      if (state.deletedRetailer.length) {
        data = Object.assign(data, { deletedkeyretailer: state.deletedRetailer })
      }

      //delete distributor value
      if (state.deletedReseller.length) {
        data = Object.assign(data, { deletedkeyreseller: state.deletedReseller });
      }

      //setting company registration
      let companyRegistration = {
        company_logo_url: state.documentId,
        company_url: state.companyLogo
          ? process.env.REACT_APP_CDN_URL +
          state.companyLogo
          : process.env.REACT_APP_CDN_URL +
          "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg",
        company_name: state.companyName,
        is_same_address: 0,
        reg_address_id: +state.regAddressId,
        reg_country_id: +state.country.id,
        reg_address: state.legalAddress,
        reg_postal_code: state.zipCode,
        reg_city: state.city,
        certificates: certificates,
        company_phone1: state.phoneNumber1 + '',
        profileTypeId: +state.DistributorProfileValue.childid,
      }

      let specialityValue = state.speciality.map(x => ({ specialityId: +x.id }));

      //setting trade information
      let tradeInformation = {
        company_about: state.tweetPitch,
        // company_expectation : state.sourcing?state.sourcing:null,
        companyDesc: state.companyDesc ? state.companyDesc : null,
        productCategories: productCategories,
        productFamilies: productFamilies,
        brand: [].concat(state.brandList.map(x => ({ id: +x.id, name: x.name, documentPath: x.documentpath, isNew: x.isNew }))),
        ...(specialityValue.length && { distributorSpeciality: specialityValue }),
      }

      //setting channels

      let channels = {
        isOEM: state.isOEM,
        ...data, //keypartners
        customerProfiles: customerProfiles,
        no_of_retailers: state.no_of_retailers,
        no_of_resellers: state.no_of_resellers,
        keyretailers: state.RetailerDesc,
        keyresellers: state.ResellerDesc,
      }

      //Maturity level
      let mLevel = [];
      if (state.areYouStartUp == '1') {
        mLevel = state.startUpType.map(x => x.value);
      }

      //setting facts and figures
      let facts = {
        year_of_estabilishment: +state.yearOfEstablishment,
        startup: state.areYouStartUp,
        maturitylevel: state.areYouStartUp == "1" ? mLevel : [],
        no_of_skus: state.no_of_skus ? +state.no_of_skus : null,
      }

      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.no_of_staffs))
        facts['employeesRange'] = state.no_of_staffs
      else
        facts['employeesCount'] = +state.no_of_staffs

      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.annualTurnOver))
        facts['annualTurnoverRange'] = state.annualTurnOver
      else
        facts['annualTurnover'] = +state.annualTurnOver

      //setting marketing info
      let marketing = {
        socialProfile: socialProfile,
        company_website_url: state.websiteUrl,
        award: award,
        certificatesList: state.keyTags.filter(el => !el.certificatetagid),
        deletedTags: state.deletedTags,
        deleted_companyImages: state.deleted_companyImages,
        companyImages: mediaState.companyImages,
        // company_mobile_no: watchAll.mobile,
        // company_email: watchAll.email,
        bannerImage: bannerImage,
      }

      if (mediaState?.companyImages?.length === 0)
        delete marketing.companyImages

      if (state.deleted_companyImages?.length === 0)
        delete marketing.deleted_companyImages

      //setting documents
      let document = {
        channel_document: productBrochure.productpdf,
        company_introvideo: state.introVideo,
      }

      if (state.deletedDocuments?.length)
        document = Object.assign(document, { deleted_channel_documents: state.deletedDocuments });

      //Trade Location
      let loc = {};
      let sellingLocation = []; let targetLocation = []; let locations = [];

      sellingLocation = state.sellingCountries.map(x => +x.regionCountryJCTId);
      loc.sellingLocation = sellingLocation;
      locations.push({
        locationType: 'SELLING',
        isUpdate: true,
        location: loc.sellingLocation,
      });

      if (userData.eventRegisterAs == 1) {
        targetLocation = state.targetCountries.map(x => +x.regionCountryJCTId);
        loc.targetLocation = targetLocation;
        locations.push({
          locationType: 'TARGET',
          isUpdate: true,
          location: loc.targetLocation,
        });
      }


      let finalData = {
        companyRegistration: companyRegistration,
        tradeInformation: tradeInformation,
        channels: channels,
        marketing: marketing,
        facts: facts,
        document: document,
        ratingId: props.ratingId ? props.ratingId : null,
        locations: locations,
        annualTurnOver: state.annualTurnOver
      }

      //uploading to api
      await commonService.postService(finalData, '/setting/update_company_profile').then(res => {
        ToastsStore.success('Saved Successfully!');

        if (props.ratingId) {
          history.push('/admin/dashboard');
        }

        if (userData.businessType != state.isOEM) {
          let existing = JSON.parse(localStorage.getItem('CHuser'));
          existing['businessType'] = +state.isOEM;
          localStorage.setItem('CHuser', JSON.stringify(existing));
        }
        getCompanyData();
      }).catch(err => {
        ToastsStore.error('Something Went Wrong!');
        console.log('erroncompany', err);
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateTradeInfo' }, '/error');
      })

    }
    catch (err) {
      ToastsStore.error('Something Went Wrong!');
      console.log('erroncompany', err);
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateTradeInfo' }, '/error');
    }


  }

  //helper function
  const isEqual = (a, b) => {

    // if length is not equal 
    if (a.length != b.length)
      return false;
    else {
      // comapring each element of array 
      for (var i = 0; i < a.length; i++)
        if (a[i] != b[i])
          return false;
      return true;
    }
  }
  const [quill, quillref] = useState();


  // const handleChangeQuill2 = (content, delta , source, editor) => { 
  //   setState({...state, 'companyDesc' : editor.getText()});
  //   // console.log("hndle",editor.getText());
  // }
  const handleChangeEditor = value => {
    setState({ ...state, companyDesc: value, companyDesc1: value.replace(/<[^>]+>/g, '') });

  }

  const handleChange = input => e => {
    if (input == 'tradeUrls') {
      let b2b = [...state.b2bList];
      console.log('b2b', b2b, e.target.value, e.target);
      b2b[e.target.id].value = e.target.value;
      setState({ ...state, b2bList: b2b })
    }
    // else if (input == 'websiteUrl') {
    //   let web = [...state.websiteUrl];
    //   web[e.target.id].value = e.target.value;
    //   setState({ ...state, websiteUrl: web });
    // }
    else if (input == 'tweetPitch') {
      setState({ ...state, [input]: e.target.value.slice(0, 280) });
    }
    else if (input == 'companyDesc') {
      setState({ ...state, [input]: e.target.value.slice(0, 2000) });
    }
    else if (input == "linkedIn" || input == "twitter" || input == "facebook" || input == "insta") {
      setState({ ...state, [input]: e.target.value.slice(state[input + '_pre'].length,) });
      errors[input] = false;
    }
    else if (input == "isOEM") {

      setBusinessType(e.target.value);
      setState({ ...state, [input]: e.target.value });
    }
    else if (input == 'annualTurnOver' || input == 'no_of_staffs') {
      let temp = input == 'annualTurnOver' ? 'turnOver' : 'staffCnt';
      document.getElementById(temp).value = null;
      errors[temp] = null;
      setState({ ...state, [input]: e.target.value });
    } else if (input == 'turnOver' || input == 'staffCnt') {
      let temp = input == 'turnOver' ? 'annualTurnOver' : 'no_of_staffs';
      setState({ ...state, [temp]: e.target.value });
    }
    else {
      setState({ ...state, [input]: e.target.value });
    }
    console.log("add", e.target.value, e.target);

  }


  const handleChangeSelect = async (input, value) => {
    if (input == 'country') {
      setLoading(true);
      await commonService
        .getServices("/setting/getVat/" + value?.id)
        .then((res) => {
          state.isVat = res.data.country[0].isVat;
        })
        .catch((err) => {
          commonService.postService(
            {
              err: JSON.stringify(err),
              reqUrl: window.location.pathname,
              service: "/setting/getVat",
            },
            "/error"
          );
        });
      state[input] = value;
      setState({ ...state });
      setLoading(false);
    }
    else if (input == 'productFamily') {
      state.productFamilies.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state, productFamilies: state.productFamilies });
      setState({ ...state, productValue: state.productValue });

      // state.productValue= value
      // setState({...state, productValue:state.productValue});
      if (state.productValue.length == 0)
        errors.productValue = true;

      let Group = _.uniq(state.productValue.map(x => x.groupid));
      state.groupCount = Group.length;
      setState({ ...state, groupCount: state.groupCount });



    }
    else if (input == 'brand' || input == 'brands' || input == 'Reseller' || input == 'Retailer') {
      if (input == 'brands')
        input = 'brand';
      if (value.name === 'Add Brand' && value.id === 0) {
        setBrandName('');
        openAdd(true);
        return <></>;
      }
      if (value.hasOwnProperty('isNew')) {
        state[input] = value;
      } else {
        value.isNew = '0';
        state[input] = value;
      }

      setState({ ...state, [input]: state[input] });
      let temp = state[input];
      temp = state[input + 'List'].filter(x => x.id == temp.id);
      if (temp.length) {
        console.log('already entered');
      } else {
        console.log('no entry')
        if (value.isNew == '1')
          state[input + 'ListUser'].push(state[input]);
        state[input + 'List'].push(state[input]);
        if (input == 'brand') {
          state[input + 'Option'] = state[input + 'Option'].filter(x => x.id != value.id);
        }
        else {
          state[input + 'Options'] = state[input + 'Options'].filter(x => x.id != value.id);
        }
        setState({ ...state, [input + 'List']: state[input + 'List'], [input + 'ListUser']: state[input + 'ListUser'] });
      }

      state[input] = [];
      setState({ ...state });
      console.log("brandList:", state[input + 'list']);
    }
    else if (input == 'relevantChannelProfile') {
      state.ChannelProfileOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.ChannelProfileValue = value
      setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
      setState({ ...state, ChannelProfileValue: state.ChannelProfileValue });

      if (state.ChannelProfileValue.length == 0)
        errors.relevantChannelProfiles = true;
      // state.ChannelProfileValue= value
      // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    else if (input == 'relevantResellerProfile') {
      state.ResellerProfileOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.ResellerProfileValue = value
      setState({ ...state, ResellerProfileOption: state.ResellerProfileOption });
      setState({ ...state, ResellerProfileValue: state.ResellerProfileValue });

      if (state.ResellerProfileValue.length == 0)
        errors.relevantResellerProfiles = true;
      // state.ChannelProfileValue= value
      // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }

    else if (input == 'certifications') {
      console.log('value', value);
      let name = value?.name;
      let tags = {
        tag: name
      }
      state.keyTags.push(tags);
      state.certifications = null;
      setState({ ...state });
    }
    else if (input == 'startUpType') {
      errors.productBrand = null;
      setState({ ...state, [input]: value });
    }
    else if (input === 'sellingCountries') {
      state.sellingCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.sellingCountries = value
      setState({ ...state, sellingCountries: state.sellingCountries });

      if (state.sellingCountries.length === 0)
        errors.selling = true;
    }
    else if (input === 'targetCountries') {
      state.targetCountriesOption.map((item) => {
        if (value.length === 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childname!==item.childname)
          //     item.status=false;                 
          //   else if(v.childname===item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.targetCountries = value
      setState({ ...state, targetCountries: state.targetCountries });

      if (state.targetCountries.length === 0)
        errors.target = true;
    }

    else {
      if (input == "speciality" && value.length <= 3)
        setState({ ...state, [input]: value });
      else if (input != "speciality")
        setState({ ...state, [input]: value });

    }

  }

  console.log('satet', state);
  //console.log('watchAll', watchAll);
  //console.log('media', mediaState);

  const onSubmit = data => {
    if (data) {
      console.log("data:", data);
    }
  }

  // useEffect(() => {  
  //   if(!_.isEmpty(errors)&&formState.isSubmitted)
  //   {
  //     let error = Object.assign({}, errors);
  //     if(errors.tradeUrls){
  //       error.tradeUrls = error.tradeUrls.filter(x => x ? true : false);
  //     }

  //     ToastsStore.error(JSON.stringify(error));
  //     commonService.postService({err:JSON.stringify(error),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
  //   }
  // }, [errors])

  //maturity level single or multiple decision making code
  let typeId = userData?.channelTypeId;
  //Award
  const addAwards = (data) => {
    console.log('data', data);
    let index = state.awards.findIndex((obj => obj.id == data.id));
    if (index != -1) {
      if (state.awards[index].isOld)
        state.awards[index] = Object.assign(data, { documentId: state.awards[index].documentId, isOld: true });
      else
        state.awards[index] = Object.assign(data, { documentId: state.awards[index].documentId });
      setState({ ...state, awards: state.awards });
      console.log('updated', data);
    }
    else {
      let temp = state.awards;
      temp.push(Object.assign(data, { documentId: null }));
      setState({ ...state, awards: temp });
      console.log('added', data);
    }
  }

  const deleteAwards = (data) => {
    if (data?.id) {
      state.savedAwards.filter((item) => {
        if (data.id == item.id)
          state.deletedAwards.push(item.id);
      });
      const filteredList = state.awards.filter((item) => item.id !== data.id);
      state.awards = filteredList;
      setState({ ...state, awards: state.awards, deletedAwards: state.deletedAwards });
    } else {
      const filteredList = state.awards.filter((item) => item.documentId !== data.documentId);
      state.awards = filteredList;
      setState({ ...state, awards: state.awards });
    }
  }

  const deleteKeyTag = (data) => {
    console.log('data', data);
    state.keyTags = state.keyTags.filter(x => x.tag !== data.tag);
    if (data?.certificatetagid)
      state.deletedTags.push(parseInt(data.certificatetagid));
    setState({ ...state });
  }

  const handleChangeFileSelect = async (input, value, filename) => {
    if (input === 'productBrochures') {
      console.log('brochures');
      let temp = mediaState.productBrochures;
      let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      }
      else {
        console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
    } else if (input === 'companyImages') {
      console.log('companyImages');
      let temp = mediaState.companyImages;
      let files = [];
      value.map(x => {
        files.push({ channelDocumentId: parseInt(x.channelDocumentId), channelId: x.channelId, documentUrl: x.document.documentUrl, documentPath: x.document.documentPath, documentId: x.documentId })
      })
      let newFiles = files.filter(file => !mediaState.companyImages.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      }
      else {
        console.log('Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
      console.log('mediaState', mediaState);
    } else if (input === 'awardsImages') {
      console.log('awardsImages');
      let temp = state.awards;
      let files = [];
      value.map(x => {
        if (x?.channelDocumentId)
          files.push({ channelDocumentId: parseInt(x.channelDocumentId), channelId: x.channelId, documentUrl: x.document.documentUrl, documentId: x.documentId })
        else
          files.push({ id: parseInt(x.id), channelId: x.channelId, documentUrl: x.document.documentUrl, documentId: x.documentId })
      })
      let newFiles = files.filter(file => !state.awards.find(f => f.documentId === file.documentId));

      console.log('temp', temp, temp.length + newFiles.length)
      temp.push(...newFiles);
      if (state.deletedAwards?.length) {
        let docIds = [];
        temp.map(x => {
          if (x?.id)
            docIds.push(x.id);
        })
        console.log('docIds', docIds);
        state.deletedAwards = state.deletedAwards.filter(x => !docIds.includes(x));
      }
      setState({ ...state, [input]: temp });
      console.log('state', state);
      console.log('mediaState', mediaState);
    }
    else {
      let res = await handleOnDrop(input, value, filename);
      if (res) {
        console.log('res', res);
        setMediaState({ ...mediaState, [input]: value })
      };
    }
  }

  const handleChangeFileDelete = async (input, fileToBeRemoved) => {
    let filteredState = await mediaState[input].filter(item => item.filename != fileToBeRemoved);
    if (filteredState) {
      setMediaState({ ...mediaState, [input]: filteredState });
      console.log('after delete', mediaState);
    }
  }

  //functions for image
  const handleOnDrop = async (input, data, file_name) => {
    if (data) {
      setLoading(true);
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        selectImg(input, reader.result, file_name)
        //setOpen(false);          
      };

    }
  }

  const handleChangeBanner = event => {
    const data = event.target.files[0];
    console.log('files', data)

    if (data && data['type'].split('/')[0] === 'image') {
      console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log('file', file)
        console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
        console.log('image', image)
        //setOpen(false);          
      };

    }

  };

  const selectBannerImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setBannerId('');
        setMediaState({ ...mediaState, 'bannerImages': [{ image: resImagePath.data.url }] });
        setLoading(false);
      }

    }

  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const addNewB2B = () => {
    setState({ ...state, b2bList: [...state.b2bList, { id: Math.random(), value: '' }] });
  }

  const addNewUrl = () => {
    setState({ ...state, websiteUrl: [...state.websiteUrl, { id: Math.random(), value: '' }] });
  }

  const delB2B = (id) => {
    console.log('id', id);
    let del = state.deleted_B2B;
    state.b2bList.map((x, i) => {
      if (id == i && x.channel_B2B_id)
        del.push(x.channel_B2B_id);
    })
    let temp = state.b2bList.filter((r, i) => i !== id);
    setState({ ...state, b2bList: temp, deleted_B2B: del });
  }

  const delWebUrl = (id) => {
    let del = state.deletedUrl;
    state.websiteUrl.map((x, i) => {
      if (id == i && x.websiteId)
        del.push(x.websiteId);
    })
    let temp = state.websiteUrl.filter((r, i) => i !== id);
    setState({ ...state, websiteUrl: temp, deletedUrl: del });
  }

  const selectImg = async (input, data, file_name) => {
    if (data.length > 0) {

      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);

      let aws_folder = input === 'bannerImages' ? 'banner' : '';


      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folder);
      if (resImagePath) {
        if (input === 'bannerImages') {
          let temp = mediaState?.bannerImages;
          temp.push({ image: resImagePath.data.url, filename: file_name })
          setMediaState({ ...mediaState, [input]: temp });
        }
        setLoading(false);

      } else {
        loading = false;
        setLoading(loading);
      }

    }
    // setOpen(false);
  }
  const editName = (item) => {
    docName = item.name;
    setDocName(docName);
    docId = item.documentId;
    setDocId(docId);
    setNamePopup(true);
  }
  const docNameChange = async (docId, name) => {
    console.log('media', mediaState.productBrochures);
    console.log(docId, name);
    mediaState.productBrochures.map(x => {
      if (parseInt(x.documentId) === parseInt(docId))
        x.name = name;
    })
    namePopupClose();
  }
  const namePopupClose = () => {
    setNamePopup(false);
  };
  const openMediaLib = () => {
    if (mediaState.companyImages.length >= 5) {
      ToastsStore.error('Maximum 5 Images');
    } else {
      setComImage(true);
    }
  }
  const openAwardMediaLib = () => {
    if (state.awards.length >= 5) {
      ToastsStore.error('Maximum 5 Images');
    } else {
      setAwardImage(true);
    }
  }


  const setTradelocation = async () => {
    try {
      let target = []; let selling = [];
      setLoading(true);

      await axios.get('setting/getUserProfile/' + userData?.channelId).then(res => {

        state.sellingCountriesOption = res.data.regions1;
        selling = res.data.regions1;
        state.targetCountriesOption = res.data.regions;
        target = res.data.regions;

        setState({ ...state, targetCountriesOption: state.targetCountriesOption });
        setState({ ...state, sellingCountriesOption: state.sellingCountriesOption });

      }).catch(err => {
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getUserProfile' }, '/error')
      })

      await axios.get('setting/getChannelLocation').then(res => {
        target = target.map(x => {
          res.data.targetArray.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });

        selling = selling.map(x => {
          res.data.sellingArray.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        });
        state.targetCountriesOption = target;
        state.targetCountries = res.data.targetArray.length === 0 ? [] : res.data.targetArray;
        state.sellingCountries = res.data.sellingArray.length === 0 ? [] : res.data.sellingArray;
        state.sellingCountriesOption = selling
        setState({ ...state });
      }).catch(err => {
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getContactLocation' }, '/error');
      });
    }
    catch (err) {
      //ToastsStore.error('User Location Not Found!');
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setTradelocation' }, '/error');
    }
  }

  const closePopup = () => {
    setModalPopup(false);
  };

  const confirmPopup = (res) => {
    setModalPopup(false);
  }

  const closeTurnOver = () => {
    setAnnualTurn(false);
  };

  const saveTurnOver = async (value) => {
    setAnnualTurn(false);
    state.annualTurnOverList = value;
    setState({ ...state });
    console.log("saveproduct:", value);

  };

  const handleValidation = (e) => {
    const charCode = e.which || e.keyCode;
    if (e.target.value.length == 30) {
      e.preventDefault();
    }

    if (e.target.value === '' && charCode === 48) {
      e.preventDefault(); // Prevent input
    }

    if (
      (charCode >= 48 && charCode <= 57) ||
      charCode === 43 || // Plus (+)
      charCode === 45 || // Minus (-)
      charCode === 8 ||  // Backspace
      charCode === 46    // Delete
    ) {
    } else {
      e.preventDefault();
    }
  }


  return (
    <div className="main-content companyProfileDist">
      {!props.ratingId && <SimpleHeader Ispublic={true} location={props.match} />}
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      {isAnnualTurn &&
        <AnnualTurnOver open={isAnnualTurn} currentValue={state.annualTurnOverList} handleClose={closeTurnOver} handleSave={saveTurnOver} />}

      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />

      <DocumentName isOpen={namePopup} handleClose={namePopupClose} isEdit={true} handleSave={docNameChange} name={docName} docId={docId} />
      {initLoad ? <Spinner /> : <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
        {comImage &&
          <MultiSelectImages
            id={1}
            isOpen={comImage}
            multiple={true}
            limit={5}
            acceptedFiles={['image/jpeg', 'image/png', 'image/gif']}
            getBrochuresIds={handleChangeFileSelect}
            input="companyImages" aws_folderName="companyImages"
            handleClose={() => { setComImage(false) }}
            documentIds={mediaState?.companyImages.length > 0 ? mediaState.companyImages.map(x => parseInt(x.documentId)) : []}
          />
        }
        {awardImage &&
          <MultiSelectImages
            id={2}
            isOpen={awardImage}
            multiple={true}
            limit={5}
            acceptedFiles={['image/jpeg', 'image/png', 'image/gif']}
            getBrochuresIds={handleChangeFileSelect}
            input="awardsImages" aws_folderName="awards"
            handleClose={() => {
              setAwardImage(false)
            }}
            documentIds={state.awards.length > 0 ? state.awards.map(x => parseInt(x.documentId)) : []}
          />
        }
        <form noValidate onSubmit={handleSubmit(save)}>
          {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><Spinner /></div>}
          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />

          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="businessType" ref={register} name="businessType" value={businessType} control={control} />
          <Card>
            <CardHeader className="col-lg-12 justify-content-center">
              <h3 className="text-center">Company Registration</h3>
              <p className="text-center">Enter Company legal details.</p>
            </CardHeader>
            {/* <CardBody className="Profad"> */}

            <CardBody className="companyDist" >
              <div className="row ">
                <div className="col-sm-12 col-lg-12">
                  <div className="row">
                    <div className="col-sm-5 col-12 col-md-6 col-lg-3" style={{ paddingLeft: '0' }}>
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} />
                      <h4>Company Logo *</h4>
                      {!errors.companyLogo || state.companyLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Company Logo</span>}

                      <div>

                        <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={1} size={1}
                          img={state.companyLogo ? process.env.REACT_APP_CDN_URL + state.companyLogo : state.companyLogoUrl ? process.env.REACT_APP_CDN_URL_TEMP + state.companyLogoUrl : process.env.REACT_APP_CDN_URL +
                            "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />
                      </div>





                    </div>
                    <div className="col-sm-7 col-12 col-md-5 col-lg-3 mt-5">
                      <span>Accepts Jpeg / Jpg / Png Only</span>
                      <br></br>
                      <span>(Max size allowed 200 Kb)</span>
                      <span className="d-block">(* Image resolution should be 200 * 200)</span>
                    </div>

                    <div className="col-sm-2 mt-2">

                    </div>
                  </div>

                </div>

              </div>
              <div className="row">
                <div className="col-lg-12 mt-2">
                  {/* <h4 >Company Legal Name *</h4> */}
                  <FormInput className="comp-name mt-2" id="companyName" name="companyName" label="Company Legal Name *" type="text" variant="outlined" defaultValue={state.companyName} error={companyError ? { message: "Company Name already taken", required: true } : errors.companyName}
                    register={register}
                    value={watchAll.companyName}
                    onChange={handleChange('companyName')}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-3">
                  {/* <h4 >Registered/Legal Address</h4> */}
                  <FormInput className="comp-name mt-2 addrs" id="legalAddress" name="legalAddress" label="Registered/Legal Address *" type="text" variant="outlined" value={state.legalAddress} error={errors.legalAddress}
                    onChange={handleChange('legalAddress')} register={register} multiline
                  />
                </div>


                {/* <div className="col-lg-6 mt-3">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="legalAddress" name="legalAddress" ref={register} value={state.legalAddress} error={errors.legalAddress} />
                  <div className="form-group mb-0">
                    <FormTextArea
                      className="buytext2 w-100 h-auto"
                      name="note"
                      id="note"
                      rows="3"
                      type="textarea"
                      label=" Registered/Legal Address"
                      placeholder="Registered/Legal Address"
                      value={state.legalAddress}
                      onChange={handleChange('legalAddress')}
                    />
                  </div>
                </div> */}



                <div className="col-lg-6 mt-4 ">
                  <div className="row">
                    {/* <h4 >Country *</h4> */}
                    {/* <FormInput className="comp-name mt-2" id="outlined-search" label="Country *" type="text" variant="outlined" /> */}
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <FormAutocomplete
                        id="country"
                        name="country"
                        label="Country *"
                        options={state.countryList}
                        placeholder="Select Country"
                        // multiple
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}
                        control={control}
                        getOptionSelected={(options, value) => options.name === value.name}
                        input='country'
                        value={state.country}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Country'}
                        error={errors.country}
                        defaultValue={state.country}
                      />
                    </FormControl>
                  </div>
                  <div className="row">
                    {/* <h4 >Country *</h4> */}
                    <FormInput className="comp-name mt-4" id="zipCode" name="zipCode" placeholder='' label="Zip/Pincode *" type="text" variant="outlined"
                      value={watchAll.zipCode}
                      defaultValue={state.zipCode}
                      onChange={handleChange('zipCode')}
                      register={register}
                      error={errors.zipCode}
                    />
                  </div>
                  <div className="row">
                    {/* <h4 >Country *</h4> */}
                    <FormInput className="comp-name mt-4" id="city" name="city" label="City *" type="text" variant="outlined" value={watchAll.city}
                      defaultValue={state.city}
                      onChange={handleChange('city')}
                      error={errors.city}
                      register={register}
                    />
                  </div>
                </div>
              </div>


              <div className="row mt-3">
                <div className="col-sm-6 col-lg-6 col-md-8 mt-3">
                  <div className="form-group mt-4">

                    {/* <label className="form-control-label" htmlFor="">
           
           </label> */}
                    <InputGroup className="" >
                      <InputGroupAddon style={{ width: '30%' }} addonType="prepend">
                        <InputGroupText id="certificateName" className="justify-content-center ellipsisText" style={{ width: '100%' }}>{state.certificateName}</InputGroupText>
                      </InputGroupAddon>
                      <UncontrolledTooltip target="certificateName" placement='bottom'>
                        {state.certificateName}
                      </UncontrolledTooltip>
                      <FormInput style={{ width: '70%' }} className=""
                        id="CRN"
                        type="text"
                        name="CRN"
                        label="Company registration number*"
                        register={register}
                        error={errors.CRN}
                        defaultValue={state.companyRegNumber}
                        value={watchAll.CRN}
                        onChange={handleChange('companyRegNumber')}
                      />
                    </InputGroup>

                  </div>



                </div>
                <div className="col-sm-6 col-md-4 mt-3">
                  <div className="form-group">

                    <label className="form-control-label" htmlFor="">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="isVat" ref={register} name="isVat" value={state.isVat} control={control} />
                    </label>
                    {/* {state.isVat=='1' && (
<InputGroup  className="">
  <InputGroupAddon style={{width:'20%'}} addonType="prepend">
    <InputGroupText id="Vat" className="justify-content-center" style={{width:'100%'}}>VAT</InputGroupText>
  </InputGroupAddon>
  <FormInput
  style={{width:'80%'}}
  className=""
  id="VATN"
  type="text"
  name="VATN"
  label="VAT number"
  placeholder="Only for EU companies"
  register={register}
  error={errors.VATN}
  defaultValue={state.vatNumber}
  value={watchAll.VATN}
  onChange={handleChange('vatNumber')}
  />
   {state.vat_verified==1 && state.vatNumber &&
                          <div className="Checkmark">
                          <span><i className="fas fa-check text-success Checkspan"></i></span>
                        </div>}

    {state.vat_verified==0 &&  state.vatNumber && 
      <div className="Checkmark">
      <span><i className="fas fa-times text-danger Checkspan"></i></span>
    </div>}
                          
</InputGroup> )} */}

                  </div>
                </div>

                <div className="col-sm-6 col-md-6">
                  <div className="form-group">
                    <FormInput className="comp-name" id="phoneNumber1" name="phoneNumber1" label="Switchboard Number *" type="text" variant="outlined" value={watchAll.phoneNumber1}
                      onKeyPress={handleValidation}
                      onChange={handleChange('phoneNumber1')}
                      onPaste={(e) => e.preventDefault()}
                      defaultValue={state.phoneNumber1}
                      error={errors.phoneNumber1}
                      register={register}
                    />
                  </div>
                </div>

              </div>


              {/* <Row>
    <Col lg="6"  className="code">
    <div className="form-group">            

    <FormInput className="comp-name1" id="mobile" name="mobile"  label="mobile" type="text" variant="outlined" value={watchAll.mobile}
    onChange={handleChange('mobile')}
    defaultValue={state.mobile}    
    error={errors.mobile}
    register={register}    
     /> */}

              {/* <InputGroup  className="reg reg_12">
  <InputGroupAddon addonType="prepend">
    <InputGroupText></InputGroupText>
  </InputGroupAddon> */}

              {/* </InputGroup> */}
              {/* </div> 
    
    </Col>
    <Col lg="6"> */}
              {/* <FormInput className="comp-name mt-3" id="outlined-search" label="EmailID" type="text" variant="outlined" /> */}
              {/* <div className="form-group">
        <FormInput
            id="companyemail" 
            type="email" 
            label="Enter your Email" 
            name="email" 
            register={register}
            error={errors.companyemail}
            value={watchAll.email}
            defaultValue={state.email}
            onChange={handleChange('email')}
            //error={errors.companyemail ? errors.companyemail : checkMailId ? {message:"Email Id already taken", required: true} : ''}
            
            //defaultValue={email}
         />
        </div>
    </Col>
    </Row> */}

              <Row>
                <Col lg="12">
                  <FormInput className="comp-name mt-2" id="outlined-search" name="websiteUrl" label="Website URL *" type="text" variant="outlined" value={watchAll.websiteUrl}
                    defaultValue={state.websiteUrl}
                    register={register}
                    error={errors.websiteUrl}
                    onChange={handleChange('websiteUrl')}
                  />
                </Col>
              </Row>

              {state.b2bList && state.b2bList.map((x, i) => <Row>
                <Col lg="11">
                  <FormInput className="comp-name mt-4" id={i} name={`tradeUrls[${i}].value`} label="Website URLs" type="text" variant="outlined"
                    value={watchAll[`tradeUrls[${i}].value`]}
                    onChange={handleChange('tradeUrls')}
                    register={register}
                    error={errors.tradeUrls ? errors.tradeUrls[i] ? errors.tradeUrls[i].value : '' : ''}
                    defaultValue={x.value}
                  />
                </Col>
                {i == 0 ? <Col lg="1">
                  <div className="faplus">
                    <i class="fa fa-plus-circle" onClick={addNewB2B}></i>
                  </div>
                </Col> :
                  <Col lg="1">
                    <div className="faminus">
                      <i class="fa fa-minus-circle " onClick={() => delB2B(i)}></i>
                    </div>
                  </Col>}
              </Row>)}



            </CardBody>
          </Card>

          <Card className="companyDistTrade">
            <CardHeader>
              <h3 className="text-center">Trade Information</h3>
              {/* <p className="text-center">Enter your Trade information</p> */}
            </CardHeader>
            <CardBody>
              <div className="row">
                <div className="col-sm-12 mt-4 ">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} maxLength={280} />
                  {!errors.tweetPitch || (state.tweetPitch.length > 5 && state.tweetPitch.length < 280) ? <h3 style={{ fontSize: '1rem' }}>Your company’s short presentation Pitch (280 characters) *
                    {state.tweetPitch?.length > 0 && <span style={{ color: state.tweetPitch?.length > 280 ? "red" : "#9ea2a5" }}> {state.tweetPitch?.length > 280 ? 'description is too long, maximum size is 280 characters' : `remaining characters  ${280 - state.tweetPitch.length}`}</span>}
                  </h3> :
                    <h4>Your company’s short presentation Pitch (280 characters) * <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                      state.tweetPitch.length <= 1 ? 'Tweet Pitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'company short presentation must have min 5 characters' : state.tweetPitch.length > 280 ? 'company short presentation must have max 280 characters' : ''
                    }</span></h4>
                  }
                  <div className="form-group mb-0">
                    <FormTextArea
                      className="buytext2 w-100 h-auto"
                      name="note"
                      id="note"
                      rows="3"
                      type="textarea"
                      maxLength={280}
                      label=" "
                      placeholder="Tell us about your company background and vision -max 280 characters *"
                      // handleChange={handleChangeQuill1} 
                      value={state.tweetPitch}
                      onChange={handleChange('tweetPitch')}
                    // register={register}
                    //   value={note}
                    //   onChange={handleChange}
                    />

                  </div>
                  {/* {280-state.tweetPitch.length} remaining */}
                </div>
              </div>

              {/* <div className="row"> 
                <div className="col-sm-12 mt-4 ">                
                 <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="sourcing" name="sourcing" ref={register} value={state.sourcing} />  
                 {!errors?.sourcing || (state.sourcing?.length >= 5 && state.sourcing?.length <= 5000) ? <h3 style={{fontSize:'1rem'}}>Your company’s long presentation </h3> :
             <h4>Your company’s long presentation  <span style={{color: '#f44336', float: 'right', fontSize:'0.75rem'}}>{
                   state.sourcing?.length <= 5 ? 'company long presentation must have min 5 characters' : state.tweetPitch.length > 5000 ? 'company long presentation must have max 5000 characters' : ''
                  }</span></h4> 
                } 
          <div className="form-group">
          <FormTextArea
                  className="buytext2 w-100" placeholder="Tell us about your company background and vision -max 5000 characters *" onChange={handleChange('sourcing')} value={state.sourcing}/>
        </div>
        </div>
</div> */}
              <div className="row">
                <div className="col-sm-12 mt-4 ">
                  <input
                    type="text"
                    style={{
                      position: "absolute",
                      left: "-10000px",
                      top: "auto",
                      width: "1px",
                      height: "1px",
                      overflow: "hidden",
                    }}
                    id="companyDesc"
                    name="companyDesc"
                    ref={register}
                    maxLength={2000}
                    value={state.companyDesc}
                  />
                  {!errors?.companyDesc ||
                    (state.companyDesc?.length >= 8 &&
                      state.companyDesc?.length <= 2000) ? (
                    <h3 style={{ fontSize: "1rem" }}>
                      Your Company’s Full Presentation (2000 characters) *

                      {state.companyDesc?.length > 0 && <span style={{ color: state.companyDesc?.length > 2000 ? "red" : "#9ea2a5" }}> remaining characters  {2000 - state.companyDesc.length}</span>}
                    </h3>

                  ) : (
                    <h4>
                      Company’s Full Presentation{" "} *
                      <span
                        style={{
                          color: "#f44336",
                          float: "right",
                          fontSize: "0.75rem",
                        }}
                      >
                        {(!state.companyDesc || state.companyDesc === '')
                          ? "Company Description can't be empty."
                          : state.companyDesc?.length <= 5
                            ? "Company Description must have min 5 characters"
                            : state.companyDesc?.length > 280
                              ? "Company Description must have max 2000 characters"
                              : ""}
                      </span>
                    </h4>
                  )}
                  <div className="form-group mb-0">
                    {/* <FormTextArea
                      className="buytext2 w-100"
                      placeholder="Tell us about your company description and vision -max 2000 characters *"
                      onChange={handleChange("companyDesc")}
                      value={state.companyDesc}
                    /> */}
                    <ReactQuill
                      theme="snow"
                      placeholder="Tell us about your company description and vision -max 2000 characters *"
                      onChange={handleChangeEditor}
                      value={state.companyDesc}
                      // ref={register}
                      id="companyDesc"
                      name="companyDesc"
                      maxLength={2000}
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline"],
                          [
                            {
                              list: "ordered"
                            },
                            {
                              list: "bullet"
                            }
                          ]
                        ]
                      }}
                    />
                  </div>
                  {/* {2000-state.companyDesc.length} remaining */}
                </div>
              </div>

              <div className="col-lg-12 ">
                <h4 >Your Distributed Brands *</h4>
                <div className="form-group mt-3">

                  {state.brandList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand} brandList={state.brandList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="brandList" name="brandList" ref={register} value={state.brandList[0] ? 'data' : ''} />
                  {/* Autocomplete with image */}
                  <AddFieldWithPopUpAutocomplete
                    limitTags={2}
                    name="Your brands"
                    id="Brands"
                    options={state.brandOption}
                    defaultValue={state.brand}
                    value={state.brand}
                    getOptionLabel={option => option.name}
                    renderOption={option => (
                      <>
                        <div style={{
                          display: '-webkit-inline-flex',
                          padding: '1px 6px',
                          width: '75px',
                          margin: '10px 5px 0 0',
                          float: 'left',
                          border: '2px solid #e7e7e7',
                          borderRadius: '5px',
                          height: '38px'
                        }}>

                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                            maxWidth: '63px', backgroundColor: '#fff',
                            maxHeight: '34px',
                            height: 'auto',
                            width: 'auto',
                            margin: '0 auto',
                            cursor: 'pointer'
                          }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                        <span>
                          {option.name}
                        </span>
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Brands" placeholder="Brands" />
                    )}
                    label="Your distributed brands *"
                    placeholder="Your distributed brands"
                    input="brand"
                    control={control}
                    register={register}
                    errMessage={'Please Select a Brand'}
                    error={errors.Brands && !state.brandList[0] ? errors.Brands : (errors.brandList && !state.brandList[0]) ? true : false}
                    handleChangeSelect={handleChangeSelect}
                    opneAdd={openAdd}
                  />
                  {brandName && <AddBrand aws_folderName="brandlogo" opneadd={openadd} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} />}

                </div>
              </div>

              <div className="col-lg-12 ">
                <h4 className='mt-4'>Your Product categories * <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                  <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                <div className="form-group">

                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productCat" name="productCat" ref={register} value={state.productValue.length ? state.productValue[0].childname : ''} />
                  <FormAutocompleteGrouping
                    id="ProductGroup"
                    name="ProductGroup"
                    label="Product categories are you selling"
                    Options={state.productFamilies}
                    placeholder="Select Product Families"
                    input='productFamily'
                    errMessage={'Please Select a Product'}
                    error={!errors.productCat || state.productValue.length ? false : true}
                    //error={errors.ProductGroup}
                    //defaultValue={productFamilies}
                    value={state.productValue}

                    optionName='productFamilies'
                    optionValue='productValue'
                    defaultValue={state.productValue}
                    selectedItem={selectedItem}

                    handleChangeSelect={handleChangeSelect}
                    control={control}
                    register={register}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12  row ">
                  <div className="form-group text-center text-sm mb-2">
                    <h4>Are you looking for start-ups? <span id='algorithm1'><i class="far fa-handshake didy"></i></span>
                      <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm1">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                    <FormRadio
                      name="startup"
                      className="text-sm mb-0"

                      options={radioGroupOptions1}
                      control={control}
                      row
                      defaultValue={state.areYouStartUp}
                      value={state.areYouStartUp}
                      onChange={handleChange('areYouStartUp')}
                    />
                  </div></div>

                {(state.areYouStartUp == '1') && <div className="col-lg-4 mt-2">
                  <h4>What's the maturity level you would accept? * </h4>

                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="productBrand" ref={register} name="productBrand" value={state.startUpType[0] ? 'data' : ''} control={control} />


                  <FormAutocomplete
                    id="maturity"
                    name="maturity"
                    label="Maturity Level *"
                    options={startUp}
                    placeholder="Select Maturity Level"
                    multiple
                    disableCloseOnSelect
                    BlurOnSelect
                    limitTags={1}
                    getOptionSelected={(options, value) => options.name === value.name}
                    defaultValue={state.startUpType}
                    control={control}
                    input='startUpType'
                    value={state.startUpType}
                    register={register}
                    errMessage={'Please Select Maturity Level'}
                    error={errors.productBrand}
                    handleChangeSelect={handleChangeSelect}

                  />

                </div>}
              </div>

              {typeId == 3 && (
                <div className="col-lg-12 ">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="DistributorProfiles" name="DistributorProfiles" ref={register} value={state.DistributorProfileValue.childname ? state.DistributorProfileValue.childname : ''} />
                  <h4 >{typeId == 3 && ' Your Distribution Profile and speciality *'} <span id='algorithm1'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm1">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                  <div className="form-group">
                    <SingleAutocompleteGrouping
                      id="DistributorProfiles"
                      name="DistributorProfiles1"
                      label="Distributor Profiles * "
                      multiple={false}
                      Options={state.DistributorProfileOption}
                      placeholder="Channel Profiles"
                      input='DistributorProfileValue'
                      errMessage={'Please Select Distributor Profile'}
                      error={errors.DistributorProfiles}
                      value={state.DistributorProfileValue.childid ? state.DistributorProfileValue : null}
                      // defaultValue={state.DistributorProfileValue}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      register={register}
                    />

                  </div>
                </div>
              )}

              <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="profile" name="profile" ref={register} value={state.DistributorProfileValue.childname != 'broadliner' ? '1' : '0'} />

              {(typeId == 3 && Object.keys(state.DistributorProfileValue).length != 0 && state.DistributorProfileValue.childname != 'broadliner') && (
                <div className="col-lg-12 ">
                  <h4 >Speciality * <span id='algorithm2'><i class="far fa-handshake didy"></i></span>
                    <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm2">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                  <div className="form-group">

                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="speciality" name="speciality" ref={register} value={state.speciality.length ? state.speciality[0].name : ''} />
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <FormAutocomplete
                        id="speciality1"
                        name="speciality1"
                        label="Search Speciality *"
                        options={state.specialityOptions}
                        placeholder="Select Speciality"
                        input='speciality'
                        errMessage={'Please Select a Speciality'}
                        error={!errors.speciality || state.speciality.length ? false : true}
                        value={state.speciality}
                        defaultValue={state.speciality}
                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        multiple
                        disableCloseOnSelect
                        blurOnSelect
                        getOptionSelected={(options, value) => options.name === value.name}
                      />
                    </FormControl>
                  </div>
                </div>
              )}


            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h3 className="text-center" >Countries of operation<span id='algorithm'><i class="far fa-handshake didy"></i></span>
                <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h3>
              <p className="text-center">Update the list of countries where you are currently operating.</p>
            </CardHeader>
            <CardBody>
              <div className="col-lg-12 ">
                <div className="form-group">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="selling" name="selling" ref={register} value={state.sellingCountries.length ? state.sellingCountries[0].childname : ''} />
                  <FormAutocompleteGroupingRegion
                    input="sellingCountries"
                    id="SellingCountries"
                    label='Selling Locations *'
                    name="SellingCountries"
                    Options={state.sellingCountriesOption}
                    placeholder="Search"
                    errMessage='Please choose Selling Locations'
                    error={!errors.selling || state.sellingCountries.length ? false : true}
                    optionName='sellingCountriesOption'
                    optionValue='sellingCountries'
                    defaultValue={state.sellingCountries}
                    value={state.sellingCountries}
                    // loading={true}
                    selectedItem={selectedItem}
                    handleChangeSelect={handleChangeSelect}
                    control={control}
                    register={register}
                    isGroup='0'
                  />
                </div>
              </div>
            </CardBody>
          </Card>

          {userData.eventRegisterAs == 1 && typeId == 3 &&
            <Card>
              <CardHeader>
                <h3 className="text-center" >Countries of operation<span id='algorithm'><i class="far fa-handshake didy"></i></span>
                  <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h3>
                <p className="text-center">Update the list of countries where you are currently targetting.</p>
              </CardHeader>
              <CardBody>
                <div className="col-lg-12 ">
                  <div className="form-group">
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="target" name="target" ref={register} value={state.targetCountries.length ? state.targetCountries[0].childname : ''} />
                    <FormAutocompleteGroupingRegion
                      input="targetCountries"
                      id="TargetCountries"
                      label='Target Locations *'
                      name="TargetCountries"
                      Options={state.targetCountriesOption}
                      placeholder="Search"
                      errMessage='Please choose Target Locations'
                      error={!errors.target || state.targetCountries.length ? false : true}
                      optionName='targetCountriesOption'
                      optionValue='targetCountries'
                      defaultValue={state.targetCountries}
                      value={state.targetCountries}
                      // loading={true}
                      selectedItem={selectedItem}
                      handleChangeSelect={handleChangeSelect}
                      control={control}
                      register={register}
                      isGroup='0'
                    />
                  </div>
                </div>
              </CardBody>
            </Card>}


          <Card className="companyDistyourchannel">
            <CardHeader>
              <h3 className="text-center">Your Channels</h3>
              <p className="text-center"></p>
            </CardHeader>
            <CardBody>

              <div className="col-lg-6 ">
                <div className="form-group">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="isOEM" value={state.isOEM} control={control} />
                  <FormRadio
                    name="Products/OEM"
                    label="Are you selling to Retailer, Resellers or both? "
                    options={radioGroupOptions}
                    control={control}
                    row
                    onChange={handleChange('isOEM')}
                    value={state.isOEM}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 " style={{ paddingLeft: '0' }}>

                  {(state.isOEM == '3' || state.isOEM == '1') ?
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h4 >Current number of retail partners *</h4>

                        <FormInput className="comp-name " name="no_of_retailers" id="no_of_retailers" label="No. of Retailers *" type="number" variant="outlined"
                          onChange={handleChange('no_of_retailers')}
                          defaultValue={state.no_of_retailers}
                          value={watchAll.no_of_retailers}
                          error={errors.no_of_retailers}
                          inputRef={register}
                        />
                      </div>
                      <br />


                      <div className="col-lg-12 ">
                        <h4 >Profile of your Retailers  * <span id='algorithm2'><i class="far fa-handshake didy"></i></span>
                          <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm2">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length ? state.ChannelProfileValue[0].childname : ''} />
                        <div className="form-group">
                          <FormAutocompleteGrouping
                            id="relevantProfiles"
                            name="relevantProfiles"
                            label="Channel Profiles * "
                            multiple={true}
                            Options={state.ChannelProfileOption}
                            placeholder="Channel Profiles"
                            input='relevantChannelProfile'
                            errMessage={'Please Select Channel Profile'}
                            error={!errors.relevantChannelProfiles || state.ChannelProfileValue.length ? false : true}
                            value={state.ChannelProfileValue}

                            optionName='ChannelProfileOption'
                            optionValue='ChannelProfileValue'
                            defaultValue={state.ChannelProfileValue}
                            selectedItem={selectedItem}

                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                          //defaultValue={state.ChannelProfileValue}
                          />

                        </div>
                      </div>
                      <div className="col-lg-12 ">
                        <h4 >Key Retailers </h4>
                        <div className="form-group">

                          {state.RetailerList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand1} brandList={state.RetailerList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                          {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="retailerList" name="retailerList" ref={register} value={state.RetailerList[0] ? 'data' : ''} />  */}
                          {/* Autocomplete with image */}
                          <AddFieldWithPopUpAutocomplete
                            limitTags={2}
                            name="Retailers"
                            id="Retailers"
                            options={state.RetailerOptions} //{state.RetailerProfileOption}
                            defaultValue={state.Retailer}
                            value={state.Retailer}
                            getOptionLabel={option => option.name}
                            renderOption={option => (
                              <>
                                <div style={{
                                  display: '-webkit-inline-flex',
                                  padding: '1px 6px',
                                  width: '75px',
                                  margin: '10px 5px 0 0',
                                  float: 'left',
                                  border: '2px solid #e7e7e7',
                                  borderRadius: '5px',
                                  height: '38px'
                                }}>

                                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                                    maxWidth: '63px', backgroundColor: '#fff',
                                    maxHeight: '34px',
                                    height: 'auto',
                                    width: 'auto',
                                    margin: '0 auto',
                                    cursor: 'pointer'
                                  }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                                <span>
                                  {option.name}
                                </span>
                              </>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" label="Retailers" placeholder="Retailers" />
                            )}
                            label="Key Retailers"
                            placeholder="Key Retailers"
                            input="Retailer"
                            control={control}
                            register={register}
                            // errMessage={'Please Select a Key Retailers'}
                            // error={errors.Retailers&&!state.RetailerList[0]? errors.Retailers : (errors.retailerList && !state.RetailerList[0]) ? true : false}
                            handleChangeSelect={handleChangeSelect}
                            opneAdd={opneAdd1}
                          />
                          {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openRetailer} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={4} input="Retailer" countryList={state.countryList} userList={state.RetailerListUser} />}

                        </div>
                      </div>

                      <div className="">
                        <div className="col-lg-12">
                          <h4>Comments about your Retail partners</h4>
                          <div className="form-group">
                            <form>
                              <textarea
                                class="qtytext buytext2"
                                id="exampleFormControlTextarea1"
                                onChange={handleChange('RetailerDesc')}
                                rows="3"
                                value={state.RetailerDesc}
                                aria-label="empty textarea"
                                label="Comments about your Retail partners"
                                placeholder="Comments about your Retail partners">
                              </textarea>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                    : <></>
                  }

                  {/* Add Key Reseller */}

                  {(state.isOEM == '3' || state.isOEM == '2') ?
                    <>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h4 >Current number of Reseller partners * </h4>
                        <FormInput className="comp-name " name="no_of_resellers" id="no_of_resellers" label="No. of Resellers *" type="number" variant="outlined" value={watchAll.no_of_resellers}
                          onChange={handleChange('no_of_resellers')}
                          defaultValue={state.no_of_resellers}
                          error={errors.no_of_resellers}
                          inputRef={register}
                        />
                      </div>
                      <br />


                      <div className="col-lg-12 ">
                        <h4 >Profile of your Resellers  * <span id='algorithm'><i class="far fa-handshake didy"></i></span>
                          <UncontrolledTooltip className="handshak" delay={0} placement="bottom" target="algorithm">This field is included in the matching algorithm</UncontrolledTooltip></h4>
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantResellerProfiles" name="relevantResellerProfiles" ref={register} value={state.ResellerProfileValue.length ? state.ResellerProfileValue[0].childname : ''} />
                        <div className="form-group">
                          <FormAutocompleteGrouping
                            id="resellerProfiles"
                            name="resellerProfiles"
                            label="Reseller Profiles * "
                            multiple={true}
                            Options={state.ResellerProfileOption}
                            placeholder="Channel Profiles"
                            input='relevantResellerProfile'
                            errMessage={'Please Select Channel Profile'}
                            error={!errors.relevantResellerProfiles || state.ResellerProfileValue.length ? false : true}
                            value={state.ResellerProfileValue}

                            optionName='ResellerProfileOption'
                            optionValue='ResellerProfileValue'
                            defaultValue={state.ResellerProfileValue}
                            selectedItem={selectedItem}

                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                          //defaultValue={state.ChannelProfileValue}
                          />

                        </div>
                      </div>
                      <div className="col-lg-12 ">
                        <h4 >Key Resellers</h4>
                        <div className="form-group">

                          {state.ResellerList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand2} brandList={state.ResellerList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                          <AddFieldWithPopUpAutocomplete
                            limitTags={2}
                            name="Resellers"
                            id="Resellers"
                            options={state.ResellerOptions} //{state.RetailerProfileOption}
                            defaultValue={state.Reseller}
                            value={state.Reseller}
                            getOptionLabel={option => option.name}
                            renderOption={option => (
                              <>
                                <div style={{
                                  display: '-webkit-inline-flex',
                                  padding: '1px 6px',
                                  width: '75px',
                                  margin: '10px 5px 0 0',
                                  float: 'left',
                                  border: '2px solid #e7e7e7',
                                  borderRadius: '5px',
                                  height: '38px'
                                }}>

                                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                                    maxWidth: '63px', backgroundColor: '#fff',
                                    maxHeight: '34px',
                                    height: 'auto',
                                    width: 'auto',
                                    margin: '0 auto',
                                    cursor: 'pointer'
                                  }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                                <span>
                                  {option.name}
                                </span>
                              </>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" label="Resellers" placeholder="Resellers" />
                            )}
                            label="Key Resellers"
                            placeholder="Key Resellers"
                            input="Reseller"
                            control={control}
                            register={register}
                            // errMessage={'Please Select a Key Retailers'}
                            // error={errors.Retailers&&!state.RetailerList[0]? errors.Retailers : (errors.retailerList && !state.RetailerList[0]) ? true : false}
                            handleChangeSelect={handleChangeSelect}
                            opneAdd={opneAdd2}
                          />
                          {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openReseller} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={5} input="Reseller" countryList={state.countryList} userList={state.ResellerListUser} />}

                        </div>
                      </div>

                      <div className="">
                        <div className="col-lg-12">
                          <h4 >Comments about Reseller partners</h4>
                          <textarea
                            class="qtytext buytext2"
                            id="exampleFormControlTextarea1"
                            onChange={handleChange('ResellerDesc')}
                            rows="3"
                            value={state.ResellerDesc}
                            aria-label="empty textarea"
                            label="Comments about Reseller partners"
                            placeholder="Comments about your Reseller partners">
                          </textarea>
                        </div>
                      </div>
                    </> : <></>}

                </div>
              </div>

            </CardBody>
          </Card>


          <Card>
            <CardHeader className="facts-figures">
              <h3 className="text-center">Facts & Figures </h3>
            </CardHeader>
            <CardBody>
              <div className="col-sm-6 mt-2">
                <FormInput
                  className="comp-name mt-2"
                  id="Yearestablishment"
                  name="Yearestablishment"
                  label="Year of Establishment *"
                  placeholder="Year of Establishment"
                  type="text"
                  variant="outlined"
                  value={watchAll.yearOfEstablishment}
                  defaultValue={state.yearOfEstablishment}
                  error={errors.Yearestablishment}
                  register={register}
                  onChange={handleChange('yearOfEstablishment')}

                />
              </div>


              <div className="row ">
                {/* <div className="col-lg-8 col-md-8 mt-3 mb-3">
                  <h4 className="text-sm mb-0">Annual Turnover *</h4>
                  {!errors.annualTurnOver || state.annualTurnOverList.length ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select or Enter Annual Turnover</span>}
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="annualTurnOver" name="annualTurnOver" ref={register} value={state.annualTurnOverList} />
                  <div className="row ">
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
                      <input className="btn btn-secondary text-center btn-lg-sm-md"
                        type="button" id="emprange" name="emprange"
                        value="Add Annual TurnOver" onClick={() => { setAnnualTurn(true) }} />
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-8 col-md-12 mt-3">
                  <h4 className="text-sm mb-0">Annual Turnover *{!errors.annualTurnOver || state.annualTurnOver ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select or Enter Annual Turnover</span>}</h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="annualTurnOver" name="annualTurnOver" ref={register} value={state.annualTurnOver} />
                  <div className="row turnOver_Btn">
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
                      <input className={(state.annualTurnOver === '<1M€' || state.annualTurnOver === '1') ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"} type="button" id="emprange" name="emprange"
                        value="<1M€" onClick={handleChange('annualTurnOver')} /></div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
                      <input className={(state.annualTurnOver === '1-10M€' || state.annualTurnOver === '2') ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="1-10M€" onClick={handleChange('annualTurnOver')} /></div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm  ">
                      <input className={(state.annualTurnOver === '10-100M€' || state.annualTurnOver === '3') ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="10-100M€" onClick={handleChange('annualTurnOver')} /></div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 pl-4 text-center res-sm  ">
                      <input className={(state.annualTurnOver === '>100M€' || state.annualTurnOver === '4') ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value=">100M€" onClick={handleChange('annualTurnOver')} /></div>
                  </div>
                </div>



                <div className="col-lg-4 col-md-12 col-xs-3 mt-5 ">
                  <div className="form-group ">


                    <FormInput
                      id="turnOver"
                      type="number"
                      name="turnOver"
                      label="Enter Value in M€ *"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">M€</InputAdornment>
                      }}
                      defaultValue={(state.annualTurnOver !== '>100M€' && state.annualTurnOver !== '10-100M€' && state.annualTurnOver !== '1-10M€' && state.annualTurnOver !== '<1M€' && state.annualTurnOver != '1' && state.annualTurnOver != '2' && state.annualTurnOver != '3' && state.annualTurnOver != '4') ? parseFloat(state.annualTurnOver).toFixed(3) : ''}
                      value={watchAll.turnOver}
                      onChange={handleChange('turnOver')}
                      error={errors.turnOver}
                      register={register}
                      control={control}
                    />
                  </div>
                </div>
              </div>

              <div className="row" >
                <div className="col-lg-8">
                  <h4 className="text-sm mb-0" >No of Staffs *{!errors.no_of_staffs || state.no_of_staffs ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select or Enter No Of Employees</span>}</h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="no_of_staffs" ref={register} name="no_of_staffs" value={state.no_of_staffs} control={control} />
                  <div className="row turnOver_Btn" >
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
                      <input className={state.no_of_staffs == '1-10' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="1-10" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm"  >
                      <input className={state.no_of_staffs === '11-50' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="11-50" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm" >
                      <input className={state.no_of_staffs === '51-100' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="51-100" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center  res-sm" >
                      <input className={state.no_of_staffs === '101-Above' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="101-Above" onClick={handleChange('no_of_staffs')} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mt-4 ">
                  <div className="form-group  mt-2">

                    {/* {state.no_of_staffs != '' && */}
                    <FormInput
                      id="staffCnt"
                      type="number"
                      name="staffCnt"
                      label="Enter Custom Value *"
                      placeholder="Enter Your Value"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                      }}
                      defaultValue={state.no_of_staffs}
                      value={watchAll.staffCnt}
                      onChange={handleChange('staffCnt')}
                      control={control}
                      register={register}
                      error={errors.staffCnt}
                    />
                  </div>
                </div>
              </div>

              {userData.eventRegisterAs == 1 &&
                <div className="col-lg-12">
                  <h4>No of SKU's *</h4>
                  <h5  >Enter the number of products that you handle</h5>
                  <div className="form-group">
                    <FormInput
                      id="no_of_skus"
                      type="number"
                      name="no_of_skus"
                      label="No of SKU's *"
                      InputProps={{
                        inputProps: {
                          max: 100, min: 10
                        }
                      }}
                      onWheel={(event) => event.target.blur()}
                      onChange={handleChange('no_of_skus')}
                      control={control}
                      register={register}
                      defaultValue={state.no_of_skus}
                      value={watchAll.no_of_skus}
                      error={errors.no_of_skus}
                    />
                  </div>
                </div>}




            </CardBody>
          </Card>
          {/* {state.websiteUrl && state.websiteUrl.map((x,i) => <Row>
      <Col lg="11">
      <FormInput className="comp-name mt-4" id={i} name={`websiteUrl[${i}].value`} label="Website URLs" type="text" variant="outlined" 
      value={watchAll[`websiteUrl[${i}].value`]}
      onChange={handleChange('websiteUrl')}
      register={register}
      error={errors.websiteUrl ? errors.websiteUrl[i] ? errors.websiteUrl[i].value : '' : ''}
      defaultValue={x.value}
      />
      </Col>
      {i == 0 ? <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addNewUrl}></i>
      </div>
      </Col> : 
      <Col lg="1">
        <div className="faminus">
        <i class="fa fa-minus-circle " onClick={() => delWebUrl(i)}></i>
      </div>
      </Col>}
    </Row>)}
 */}
          {/* <Card> */}
          {/* <CardHeader> */}
          <div className="companyprofile_option-header">
            <h1 className="text-center">Optional Information</h1>
            <h5 className="text-center">Enhance your profile by adding additional information about your company</h5>
          </div>
          {/* </CardHeader> */}
          {/* </Card> */}
          <Card>
            <CardHeader>
              <h3 className="text-center">Marketing Information</h3>
            </CardHeader>
            <CardBody>

              {/* <p className="text-center">Add your company’s social profiles like Company Website URL, linkedin, Instragram Profile Pages.</p> */}

              <h4 className='mt-4' style={{ paddingLeft: "15px" }}>Social Profiles</h4>
              <Row>
                <Col lg="12">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="linkedIn" ref={register} name="linkedIn" value={state.linkedIn} defaultValue={state.linkedIn} control={control} />
                  <a style={{ float: 'right', marginTop: '1rem' }} href={state.linkedIn_pre + state.linkedIn} target="_blank">View Link</a>
                  <FormInput className="comp-name mt-4" id="outlined-search" name="linkedIn1" label="Linkedin Handle" type="text" variant="outlined" value={state.linkedIn_pre + state.linkedIn}
                    onChange={handleChange('linkedIn')}
                    // register={register}
                    // error={!(/^(?=[a-zA-Z0-9._-]{0,100}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.linkedIn)) && state.linkedIn ? {message:'Please Enter Valid LinkedIn Handle', required: true} : errors.linkedIn}
                    error={errors.linkedIn}
                    defaultValue={state.linkedIn_pre + state.linkedIn}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="twitter" ref={register} name="twitter" value={state.twitter} defaultValue={state.twitter} control={control} />
                  <a style={{ float: 'right', marginTop: '1rem' }} href={state.twitter_pre + state.twitter} target="_blank">View Link</a>
                  <FormInput className="comp-name mt-4" id="outlined-search" name="twitter1" label="Twitter Handle" type="text" variant="outlined" value={state.twitter_pre + state.twitter}
                    onChange={handleChange('twitter')}
                    // register={register}
                    // error={!(/^(?=[a-zA-Z0-9._-]{0,15}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.twitter)) && state.twitter ? {message:'Please Enter Valid Twitter Handle', required: true} : errors.twitter}
                    error={errors.twitter}
                    defaultValue={state.twitter}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="insta" ref={register} name="insta" value={state.insta} defaultValue={state.insta} control={control} />
                  <a style={{ float: 'right', marginTop: '1rem' }} href={state.insta_pre + state.insta} target="_blank">View Link</a>
                  <FormInput className="comp-name mt-4" id="outlined-search" name="insta1" label="Instagram Handle" type="text" variant="outlined" value={state.insta_pre + state.insta}
                    onChange={handleChange('insta')}
                    // register={register}
                    // error={!(/^(?=[a-zA-Z0-9._-]{0,30}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.insta)) && state.insta ? {message:'Please Enter Valid Instagram Handle', required: true} : errors.insta}
                    error={errors.insta}
                    defaultValue={state.insta}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="facebook" ref={register} name="facebook" value={state.facebook} defaultValue={state.facebook} control={control} />
                  <a style={{ float: 'right', marginTop: '1rem' }} href={state.facebook_pre + state.facebook} target="_blank">View Link</a>
                  <FormInput className="comp-name mt-4" id="outlined-search" name="facebook1" label="Facebook Handle" type="text" variant="outlined" value={state.facebook_pre + state.facebook}
                    onChange={handleChange('facebook')}
                    // register={register}
                    // error={!(/^(?=[a-zA-Z0-9._-]{0,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(state.facebook)) && state.facebook ? {message:'Please Enter Valid Facebook Handle', required: true} : errors.facebook}
                    error={errors.facebook}
                    defaultValue={state.facebook}
                  />
                </Col>
              </Row>
              {/* <Row>
      <Col lg="11">
      <FormInput className="comp-name mt-4" id="outlined-search" name="tradeUrls" label="Other B2B or Trade Portals Website URLs" type="text" variant="outlined" value={state.tradeUrls}
      onChange={handleChange('tradeUrls')}
      register={register}
      error={errors.tradeUrls}
      defaultValue={state.tradeUrls}
      id='1'
      />
      </Col>
      <Col lg="1">
        <div className="faplus">
      <i  class="fa fa-plus-circle" onClick={addNewB2B}></i>
      </div>
      </Col>
    </Row> */}
            </CardBody>
          </Card>

          <Card className='mt-4 mb-0'>
            <CardHeader>
              <h3 className="text-left">Welcome Video</h3>
              <p className="text-left">Add an introduction video that buyers will view on your company profile (max 90 seconds).</p>
            </CardHeader>
            <CardBody>
              <div className="form-group">
                <form action="/file-upload" className="dropzone">
                  <div className="fallback">
                    <div style={{ flex: 1, display: 'flex' }}><MediaLibrary text="Upload Intro Video" introVideoId={introVideoId} id='1' size={2} />
                      {initialVideo && <span style={{ textDecoration: 'underline', color: 'orange', display: 'flex', alignItems: 'center', marginLeft: '2rem', cursor: "pointer" }} onClick={() => setOpen(true)}>Preview Video</span>}</div>
                    <VideoPreview videoModalClose={() => setOpen(false)} isOpen={open} url={initialVideo} />
                  </div>
                </form>
              </div>
            </CardBody>
          </Card>

          <Card className='mb-0'>
            <CardHeader>
              <h3 className="text-left">Awards</h3>
              <p className="text-left">Raise your profile by adding your Awards.</p>
            </CardHeader>
            <CardBody>
              <div className="col-lg-12 ">
                {/* <BrandCard award={true} addAwards={addAwards} deleteAwards={deleteAwards} awardList={state.awards} name="BMW" img="https://cdn.ces.tech/ces/media/events-experiences-images/innovation-awards/2020/honoree.png"/> */}
                <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="awardsImages" /></div>
                <span onClick={openAwardMediaLib}>
                  {
                    !state.awards.length ?
                      <div className="form-group mt-3">
                        <div tabindex="0" class="MuiDropzoneArea-root">
                          <div class="MuiDropzoneArea-textContainer">
                            <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                            <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                          </div></div></div>
                      :
                      <ImageCard list={state.awards} deleteImg={deleteAwards} />
                  }
                </span>
              </div>
            </CardBody>
          </Card>
          <Card><CardBody>
            <Col lg='12'>
              <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Certifications</h4>
              <div className="w-100 overall">
                <div className=" ">

                  {state.keyTags.length > 0 &&
                    <div>
                      <Row>
                        {state.keyTags.map(x => {
                          return (
                            <Col lg="3" className="p-0 m-0 ">
                              <div style={{ width: '80%' }}>
                                <div className="fav-cl1 pointer" onClick={() => deleteKeyTag(x)}>X</div>
                                <Badge className="B-prod1  fav-list">{x.tag}</Badge>
                              </div>
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  }

                  <div className="col-lg-12 fav-ser mt-3">
                    <div className="form-group">
                      <NewFieldAutocomplete
                        id="Certifications"
                        type="text"
                        label="Add any certifications related to your company (ex ISO certification)"
                        name="Certifications"
                        options={[]}
                        placeholder="Add any certifications related to your company (ex ISO certification)"
                        // error={errors.postalcode}
                        disableCloseOnSelect
                        // limitTags={2}
                        //defaultValue={{name:'raja', id:1}}
                        control={control}
                        input='certifications'
                        value={state.certifications}
                        //     errMessage={CompanyError ? 'Company name already taken' : companyName[0]? 'Company name must be minimum 2 characters' : 'Please enter company name'}
                        //     error={errors.companyName ? errors.companyName : CompanyError}   
                        handleChangeSelect={handleChangeSelect}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </CardBody></Card>

          <Card>
            <CardBody>

              <div className="col-lg-12">
                <h4 style={{ textAlign: 'left' }}>Your Company Pictures</h4>
                <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts Jpeg / Jpg / Png, File Size Should Not Exceed 5 mb)</h5>
                <p>Add your office, warehouse pictures</p>
                {/* This below div is just used to inherit mui-dropzone css classes */}
                <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="companyImages" /></div>
                <span onClick={openMediaLib}>
                  {
                    !mediaState?.companyImages?.length ?
                      <div className="form-group mt-3">
                        <div tabindex="0" class="MuiDropzoneArea-root">
                          <div class="MuiDropzoneArea-textContainer">
                            <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                            <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                          </div></div></div>
                      :
                      <ImageCard list={mediaState.companyImages} deleteImg={deleteImg} />
                  }
                </span>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>

              <div className="col-lg-12 bannerImg ">
                <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Your Banner Image (this banner will be displayed on your public profile)</h4>
                <h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                <div className="form-group mt-3" style={{ height: 'auto' }}>
                  {/* <MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"/> */}
                  <BannerUploader img={bannerId ? process.env.REACT_APP_CDN_URL + imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc : bannerPic}
                    handleChange={handleChangeBanner} selectImg={selectBannerImg} image={image} handleCropClose={e => setImage('')}
                  />
                  {loading && <div style={{
                    position: 'relative', display: 'block',
                    top: '50%',
                    left: '50%',
                    zIndex: '1000',
                  }}><Spinner /></div>}
                </div>
                {/* <div className="ban-preview mt-3">
                     <img  onError={(event)=>{axios.post('/media/errorLog', {url:event.target.src, documentTypeId:1, name:null}); event.target.setAttribute("src","http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg");}}  src={bannerId ? process.env.REACT_APP_CDN_URL+imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP+imageSrc : 'http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}/>
                    </div>       */}
                <div className="text-right"><span><i>* 2048 X 400 (Use a resolution of 5:1) </i></span></div>

              </div>
            </CardBody>
          </Card>







          <Card>
            <CardHeader>
              <h3 className="text-center">Documents / Resources</h3>
            </CardHeader>
            <CardBody>

              <Card>
                <CardBody className="officialDocuments">
                  <div className="col-lg-12">
                    <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Official Documents</h4>
                    <p>upload your company brochure, catalogue, annual report etc...</p>
                    <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb)</h5>
                    {/* This below div is just used to inherit mui-dropzone css classes */}
                    <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages" /></div>
                    <span onClick={() => {
                      if (mediaState?.productBrochures?.length >= 5) {
                        ToastsStore.error('Maximum 5 Images');
                      } else {
                        setProdImage(true);
                      }
                    }}>
                      {
                        !mediaState?.productBrochures?.length ?
                          <div className="form-group mt-3">
                            <div tabindex="0" class="MuiDropzoneArea-root">
                              <div class="MuiDropzoneArea-textContainer">
                                <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                                <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                              </div></div></div>
                          :
                          <PdfCard pdfList={mediaState.productBrochures} deletePdf={pdfDelete} editName={editName} />
                      }
                    </span>

                    {prodImage && <ImageLibraryMultipleUploads id={1} isOpen={prodImage} multiple={true} limit={1} acceptedFiles={['application/pdf']} getBrochuresIds={handleChangeFileSelect} input="productBrochures" aws_folderName="broucher" handleClose={() => setProdImage(false)} documentIds={mediaState.productBrochures.length > 0 ? mediaState.productBrochures.map(x => parseInt(x.documentId)) : []} />}

                  </div>
                  <div className="text-center mt-4 mb-4">
                    <button type="submit" className="btn btn-primary" >Save</button>
                  </div>


                </CardBody>
              </Card>

            </CardBody>
          </Card>


        </form>
      </Container>}
    </div >


  );
};

export default CompanyProfileDist;

const radioGroupOptions = [
  {
    value: "1",
    label: "Retailer",
  },
  {
    value: "2",
    label: "Resellers",
  },
  {
    value: "3",
    label: "Both",
  }
];

const startUp = [
  { name: 'Prototype', id: 1, value: 'PROTOTYPE' },
  { name: 'Shipping to first backers', id: 2, value: 'S_BACKER' },
  { name: 'Shipping in volume', id: 3, value: 'S_VOLUME' },
];

const radioGroupOptions1 = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },

];