import React, { useState, useEffect } from "react";
import {
  Card, CardBody, CardHeader, Container, FormGroup, Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup, Col, Row, Badge
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { QuillEditor, FormInput, SingleAutocompleteGrouping, FormRadio, FormAutocomplete, FormAutocompleteGrouping, AddFieldWithPopUpAutocomplete, FormCheckBox } from "_components/FormElements/FormInput";
import MediaLibrary from "views/Hoc/Dialog/MediaLibrary";
import { BrandCard, ProfileCard } from "views/Hoc/BrandCard";
import { history } from '../../../../_helpers';
import { dashboardActions } from '../../../../_actions';
import { YourChannelSchema } from '../validation';
import AddBrand from "views/Hoc/Dialog/AddBrand";
import AddKeyPartner from "views/Hoc/Dialog/AddKeyPartner";
import { useBeforeFirstRender } from '_hooks/index'
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import { ModalSpinner } from 'views/Hoc/Spinner';
import { Spinner } from 'views/Hoc/Spinner';
import { InputLabel, Select, TextField, FormControl, InputAdornment, FormHelperText } from '@material-ui/core';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { commonService } from '_services/common.service';
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";
var _ = require('lodash');

const YourChannel = (props) => {

  let maturitylevel = false;
  const [opneadd, opneAddnew] = useState(false);
  const [previlege, setPrevilege] = useState(null);
  const [openDist, setOpenDist] = useState(false);
  const [openRetailer, setOpenRetailer] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(YourChannelSchema),
  });

  //localStorage
  var userData = JSON.parse(localStorage.getItem('CHuser'));
  let typeId = userData?.channelTypeId;
  let businessType = userData?.businessType;

  const [state, setState] = useState({
    profile: '',
    areYouStartUp: '0',
    no_of_staffs: '',
    startUpType: '',
    sales: null,
    marketing: null,
    technical: null,
    purchase: null,
    domesticRevenue: null,
    internationalRev: null,
    frenchTech: 'No',
    annualTurnOver: '',
    productBrand: [],
    brand: [],
    brandList: [],
    brandOption: [],
    productGroup: [],
    productValue: [{ childid: "4", childname: "AV accessories", groupid: "1", groupname: "AV", status: true }],
    productFamilies: [],
    DistributorProfileOption: [],
    DistributorProfileValue: [],
    Distributor: [],
    DistributorList: [],
    RetailerProfileOption: [],
    Retailer: [],
    RetailerList: [],
    ChannelProfileValue: [{ childid: "3", childname: "broadliner", status: true }],
    ChannelProfileOption: [],
    listOfServicesOption: [],
    listOfServiceValue: [],
    groupCount: 0,
    no_of_skus: null,
    storeCount: null,
    no_of_distributors: null,
    no_of_retailers: null,
    distributorDesc: '',
    RetailerDesc: '',
    startUpType1: false,
    startUpType2: false,
    startUpType3: false,
    profileTypeId: '',
    KeyRetData: [],
    RetailerOptions: [],
    KeyDistData: [],
    DistributorOptions: [],
    testRetailerList: [],
    testDistributorList: [],
    deletedRetailer: [],
    deletedDistributor: [],
    speciality: [],
    specialityOptions: [],
    countryList: [],

    brandListUser: [], //dont worry about it. it's dummy but dont remove it.
    RetailerListUser: [],
    DistributorListUser: [],


    //keyreseller popup
    no_of_resellers: null,
    ResellerDesc: '',
    ResellerProfileValue: [],
    ResellerProfileOption: [],

    Reseller: [],
    ResellerList: [],
    KeyResData: [],
    ResellerOptions: [],
    testResellerList: [],
    deletedReseller: [],
    ResellerListUser: [],

    sellingCountries: [],
    sellingCountriesOption: [],
    targetCountries: [],
    targetCountriesOption: [],
    locationType: (typeId == 2 || typeId == 3) ? 'Target Locations' : 'Selling Locations',
    title: (typeId == 2 || typeId == 3) ? 'TARGET' : 'SELLING',
    sellingZones: [],
    EMEA: false,
    APAC: false,
    AMERICAS: false,
    EMEA1: false,
    APAC1: false,
    AMERICAS1: false,
    isCompanyPublic: '1'

  })

  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, second_message: null, cid: null, JctId: null });

  const [fl, setFl] = useState(false);
  const [openReseller, setOpenReseller] = useState(false);


  //maturity level single or multiple decision making code

  if (typeId == 3 || typeId == 4)
    maturitylevel = true;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getTradeInfo();
      getAccessPrevilege();
      setLoading(false);
    })();

  }, []);

  const getAccessPrevilege = () => {
    (async () => {
      await axios.get('user/userActionPrevilege/' + 2).then(res => {
        setPrevilege(res.data.length > 0 ? res.data[0] : 0);
      }).catch(err => {
        ToastsStore.error('No Access Found!');
      });
    })();
  }


  const getCompanyDataOld = async () => {
    const apiUrl = '/setting/getCompanyDataOld';

    await axios.get(apiUrl).then(async res => {

      if (res.data) {
        let reseller = res.data.profile?.filter(x => x.groupname == 'Reseller');
        let retailer = res.data.profile?.filter(x => x.groupname == 'Retailer');

        state.ChannelProfileValue = retailer?.length ? retailer : state.ChannelProfileValue;
        state.ResellerProfileValue = reseller?.length ? reseller : state.ResellerProfileValue;
        setState({
          ...state, ChannelProfileValue: state.ChannelProfileValue,
          ResellerProfileValue: state.ResellerProfileValue,
          profileTypeId: +res.data.profileTypeId ? +res.data.profileTypeId : state.profileTypeId,
        });

        state.no_of_resellers = res.data.no_of_resellers;
        state.no_of_retailers = res.data.no_of_retailers;
        state.no_of_distributors = res.data.no_of_distributors;
                
        state.RetailerDesc = res.data.keyretailers;
        state.ResellerDesc = res.data.keyresellers;
        state.distributorDesc = res.data.keydistributors;

        await axios.get('/setting/oldKeyPartnerOptions/' + userData.channelTypeId + '/' + res.data.oldChannelId).then(res => {
          state.RetailerList = res.data.channelKeyRetailer;
          state.ResellerList = res.data.channelKeyReseller;
          state.DistributorList = res.data.channelKeyDistributor;   
          
        });

      }
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
      setLoading(false);
    });

  }

  const getTradeInfo = async () => {
    // setLoading(true);
    const countryUrl = '/common/getSignup/' + userData.channelTypeId;
    await axios.get(countryUrl).then(res => {
      //console.log("profiledata:",res.data);          
      state.countryList = res.data.country;
      setState({ ...state });
    });

    await axios.get('setting/getInitialProfile').then(res => {
      state.productFamilies = res.data.productGroup;

      let key = ['Retailer'];
      state.ChannelProfileOption = res.data.profileList.filter(i => key.includes(i.groupname));

      let key1 = ['Reseller'];
      state.ResellerProfileOption = res.data.profileList.filter(i => key1.includes(i.groupname));

      setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
      setState({ ...state, ResellerProfileOption: state.ResellerProfileOption });

    }).catch(err => {
      //ToastsStore.error('Company Not Found!')
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getInitialProfile' }, '/error')
      setLoading(false);
    });

    const apiUrl = '/setting/tradeinfo';
    await axios.get(apiUrl).then(res => {
      //console.log("profiledata:",res.data);          
      //state.countryList=res.data.country;
      state.isCompanyPublic = res.data.isCompanyPublic ? res.data.isCompanyPublic : '0';
      if (maturitylevel && res.data.maturitylevel) {
        res.data.maturitylevel.map(x => {
          if (x == 'PROTOTYPE') state.startUpType1 = true;
          if (x == 'S_BACKER') state.startUpType2 = true;
          if (x == 'S_VOLUME') state.startUpType3 = true;
          state.startUpType = 'set';
        })
      } else {
        if (res.data.maturitylevel)
          state.startUpType = res.data.maturitylevel[0];
      }

      state.DistributorOptions = res.data.keyPartners.keyDistributorOptions;
      state.RetailerOptions = res.data.keyPartners.keyRetailerOptions;
      state.DistributorList = res.data.channelKeyDistributor;
      state.RetailerList = res.data.channelKeyRetailer;
      state.listOfServicesOption = res.data.serviceListOptions;
      state.listOfServiceValue = res.data.serviceListValues;
      state.testRetailerList = res.data.channelKeyRetailer.map(x => x.id);
      state.testDistributorList = res.data.channelKeyDistributor.map(x => x.id);
      state.specialityOptions = res.data.specialityOptions.map(x => ({ id: x.productGroupId, name: x.productGroup }));
      state.speciality = res.data.channelSpeciality.map(x => ({ id: x.productGroup.id, name: x.productGroup.name }));

      if (res.data.profileType.length) {
        state.DistributorProfileValue = Object.assign(res.data.profileType[0], { firstLetter: res.data.profileType[0].groupname });
        state.DistributorProfileOption = state.DistributorProfileOption.map(x => {
          if (x.childid == state.DistributorProfileValue.childid)
            return { ...x, status: true }
          else
            return x;
        })
      }

      if (res.data.product.length && state.productFamilies.length) {
        state.productFamilies = state.productFamilies.map(x => {
          res.data.product.map(y => {
            if (x.groupid == y.groupid && x.childid == y.childid) {
              x.status = true;
            }
          })
          return x;
        })
      }

      //brandList:res.data.brand,productValue:res.data.product,storeCount:res.data.storeCount,no_of_skus:res.data.no_of_skus,no_of_staffs:res.data.empCnt.replace(/\s/g, "").trim(),annualTurnOver:res.data.turnoverRange.replace(/\s/g, "").trim(),
      //sales: res.data.sales?+res.data.sales:'', marketing: res.data.marketing?+res.data.marketing:'', technical: res.data.technical?+res.data.technical:'',
      //purchase: res.data.purchase?+res.data.purchase:'',domesticRevenue:typeId=='4'?res.data.turnoverOnStore:res.data.revD,internationalRev:typeId=='4'?res.data.onlineStore:res.data.revI,
      setState({
        ...state, ChannelProfileValue: (userData.channelTypeId == '4' || userData.channelTypeId == '5') ? res.data.profile[0] : res.data.profile,
        areYouStartUp: res.data.startup,
        no_of_distributors: res.data.no_of_distributors,
        no_of_retailers: res.data.no_of_retailers, startUpType1: state.startUpType1, startUpType2: state.startUpType2, startUpType3: state.startUpType3, startUpType: state.startUpType,
        profileTypeId: +res.data.profileTypeId, distributorDesc: res.data.keydistributors, RetailerDesc: res.data.keyretailers,
        frenchTech: res.data.isFrenchTech == '1' ? 'Yes' : 'No', productFamilies: state.productFamilies,
      });
      console.log('tradestate', state);
      // getTradeLoc();
      // setLoading(false);


    }).catch(err => {
      //ToastsStore.error('Company Not Found!')
      setLoading(false);
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/tradeinfo' }, '/error')
    });

    await axios.get('/setting/getChannelDetail').then(res => {
      state.no_of_staffs = res.data.empCnt ? res.data.empCnt : res.data.empCntRange.replace(/\s/g, "").trim();
      state.annualTurnOver = res.data.turnover ? res.data.turnover : res.data.turnoverRange.replace(/\s/g, "").trim();
      state.no_of_retailers = res.data.no_of_retailers;
      state.no_of_resellers = res.data.no_of_resellers;
      state.no_of_distributors = res.data.no_of_distributors;
      state.distributorDesc = res.data.keydistributors;
      state.RetailerDesc = res.data.keyretailers;
      state.ResellerDesc = res.data.keyresellers;
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/getChannelProduct' }, '/error')
      setLoading(false);
    });


    await axios.get('/setting/keyPartnerOptions').then(res => {
      state.RetailerList = res.data.channelKeyRetailer;
      state.ResellerList = res.data.channelKeyReseller;
      state.DistributorList = res.data.channelKeyDistributor;

      state.KeyDistData = res.data.keyPartners?.keyDistributorOptions;
      state.DistributorOptions = res.data.keyPartners?.keyDistributorOptions;
      state.KeyRetData = res.data.keyPartners?.keyRetailerOptions;
      state.RetailerOptions = res.data.keyPartners?.keyRetailerOptions;
      state.KeyResData = res.data.keyPartners?.keyResellerOptions;
      state.ResellerOptions = res.data.keyPartners?.keyResellerOptions;
      state.testRetailerList = res.data.channelKeyRetailer.map(x => x.id);
      state.testResellerList = res.data.channelKeyReseller.map(x => x.id);
      state.testDistributorList = res.data.channelKeyDistributor.map(x => x.id);

      state.DistributorOptions = state.KeyDistData;
      state.DistributorList.map(x => {
        state.DistributorOptions = state.DistributorOptions.filter(y => y.id != x.id);
      })

      state.RetailerOptions = state.KeyRetData;
      state.RetailerList.map(x => {
        state.RetailerOptions = state.RetailerOptions.filter(y => y.id != x.id);
      })

      state.ResellerOptions = state.KeyResData;
      state.ResellerList.map(x => {
        state.ResellerOptions = state.ResellerOptions.filter(y => y.id != x.id);
      })


      setState({ ...state });
    });

    await axios.get('/setting/getChannelProfile').then(res => {

      let reseller = res.data.profile?.filter(x => x.groupname == 'Reseller');
      let retailer = res.data.profile?.filter(x => x.groupname == 'Retailer');

      state.ChannelProfileValue = retailer;
      state.ResellerProfileValue = reseller;
      setState({
        ...state, ChannelProfileValue: state.ChannelProfileValue,
        ResellerProfileValue: state.ResellerProfileValue,
        profileTypeId: +res.data.profileTypeId,
      });

    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
      setLoading(false);
    })

    // if (props.ratingId) {
    //   await getCompanyDataOld();
    // }

    setLoading(false);
  }

  const setKeyOptions = (input, keyname) => {
    let temp = [];
    let tempoptions = state[keyname];
    state[input + 'List'].map(x => {
      tempoptions = tempoptions.filter(y => y.id != x.id);
    })
    console.log('deleting brand', state[input + 'Options']);
    setState({ ...state, [input + 'Options']: tempoptions });
  }

  const getTradeLoc = async () => {

    await axios.get('setting/getUserProfile/' + userData?.channelId).then(res => {

      state.targetCountriesOption = res.data.regions;
      state.sellingCountriesOption = res.data.regions1;

      setState({ ...state, targetCountriesOption: state.targetCountriesOption, sellingCountriesOption: state.sellingCountriesOption });
      console.log("pro:", res);
    }).catch(err => {
      // ToastsStore.error('Location Not Found!')
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/getUserProfile' }, '/error')
    });

    let apiUrl1 = '/setting/tradelocation';

    await axios.get(apiUrl1).then(res => {
      console.log("profiledata:", res.data);

      if (res.data.targetLocation.length && state.targetCountriesOption) {
        state.targetCountriesOption = state.targetCountriesOption.map(x => {
          res.data.targetLocation.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        })
      }

      if (res.data.sellingLocation.length && state.sellingCountriesOption) {
        state.sellingCountriesOption = state.sellingCountriesOption.map(x => {
          res.data.sellingLocation.map(y => {
            if (x.groupid == y.groupid && x.childId == y.childId)
              x.status = true;
          })
          return x;
        })
      }

      res.data.zone.tzone.map(x => {
        if (x.zoneId === '1')
          state.EMEA = true;
        if (x.zoneId === '3')
          state.APAC = true;
        if (x.zoneId === '2')
          state.AMERICAS = true;
      })


      res.data.zone.szone.map(x => {
        if (x.zoneId === '1')
          state.EMEA1 = true;
        if (x.zoneId === '3')
          state.APAC1 = true;
        if (x.zoneId === '2')
          state.AMERICAS1 = true;
      })

      state.targetCountries = res.data.targetLocation;
      state.sellingCountries = res.data.sellingLocation;
      setState({ ...state, targetCountries: res.data.targetLocation, sellingCountries: res.data.sellingLocation, sellingZones: res.data.zone, sellingCountriesOption: state.sellingCountriesOption, targetCountriesOption: state.targetCountriesOption });
      // setLoading(false);

      console.log('tradeLoc', state);
      setFl(true);
    }).catch(err => {
      //ToastsStore.error('Location Not Found!')
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/tradelocation' }, '/error')
    });



  }

  useEffect(() => {
    if (fl)
      getSearchRegion();
  }, [state.EMEA, state.AMERICAS, state.APAC, state.EMEA1, state.AMERICAS1, state.APAC1]);

  const getSearchRegion = async () => {
    let zone = [];
    if (state.title == 'TARGET') {
      if (state.EMEA) zone.push(1);
      if (state.AMERICAS) zone.push(2);
      if (state.APAC) zone.push(3);
    }
    else {
      if (state.EMEA1) zone.push(1);
      if (state.AMERICAS1) zone.push(2);
      if (state.APAC1) zone.push(3);
    }

    if (zone.length) {
      let res = await axios.post('/setting/searchRegion', {
        exclude: true,
        locationType: state.title, zone: zone, region: ''
      }).then(res => {
        if (state.title == 'TARGET') {
          state.targetCountriesOption = res.data;

          let temp = [];
          if (state.targetCountries.length) {
            temp = state.targetCountries.filter(el => state.targetCountriesOption.find(({ groupid }) => groupid == el.groupid));
            state.targetCountriesOption = state.targetCountriesOption.map(x => {
              temp.map(y => {
                if (x.groupid == y.groupid && x.childId == y.childId)
                  x.status = true;
              })
              return x;
            })
            state.targetCountries = temp;
          }

        } else if (state.title == 'SELLING') {
          state.sellingCountriesOption = res.data;

          let temp = [];
          if (state.sellingCountries.length) {
            temp = state.sellingCountries.filter(el => state.sellingCountriesOption.find(({ groupid }) => groupid == el.groupid));
            state.sellingCountriesOption = state.sellingCountriesOption.map(x => {
              temp.map(y => {
                if (x.groupid == y.groupid && x.childId == y.childId)
                  x.status = true;
              })
              return x;
            })
            state.sellingCountries = temp;
          }
        }
        setState({ ...state });
      }).catch(err => {
        //ToastsStore.error('Region Not Found!')
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'setting/searchRegion' }, '/error')
      });
    }
    else {
      if (state.title == 'TARGET') {
        state.targetCountries = [];
        state.targetCountriesOption = [];
      } else if (state.title == 'SELLING') {
        state.sellingCountries = [];
        state.sellingCountriesOption = [];
      }
      setState({ ...state });
    }
  }

  // useEffect(() => {  
  //   if(!_.isEmpty(errors)&&formState.isSubmitted)
  //   {
  //     ToastsStore.error(JSON.stringify(errors));
  //     commonService.postService({err:JSON.stringify(errors),reqUrl:window.location.pathname,service:'validation_err'},'/error')    
  //   }
  // }, [errors])



  const isEqual = (a, b) => {

    // if length is not equal 
    if (a.length != b.length)
      return false;
    else {
      // comapring each element of array 
      for (var i = 0; i < a.length; i++)
        if (a[i] != b[i])
          return false;
      return true;
    }
  }



  const deleteBrand = (id) => {
    const filteredList = state.brandList.filter((item) => item.id !== id);
    state.brandList = filteredList;
    setState({ ...state, brandList: state.brandList });
    if (state.brandList.length == 0) {
      setState({ ...state, brandValue: [] });
      errors.Brands = { message: 'Please select a Brand', type: 'required' };
    }
  }

  const deleteBrand1 = (id) => {
    const filteredList = state.DistributorList.filter((item) => {

      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedDistributor;
        temp.push(+item.keypartnerid);
        setState({ ...state, deletedDistributor: temp });
      }
      if (item.id == id && item.isNew == '1') {
        state.DistributorListUser = state.DistributorListUser.filter(x => x.id != item.id)
      }
      return item.id !== id
    });
    state.DistributorList = filteredList;

    setState({ ...state, DistributorList: state.DistributorList, DistributorListUser: state.DistributorListUser });
    if (state.DistributorList.length == 0) {
      setState({ ...state, Distributor: [] });
      // errors.Distributor={message: 'Please select Distributor', type:'required'};  
    }
    setKeyOptions('Distributor', 'KeyDistData');
  }

  const deleteBrand2 = (id) => {
    const filteredList = state.RetailerList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedRetailer;
        temp.push(+item.keypartnerid);
        setState({ ...state, deletedRetailer: temp });
      }
      if (item.id == id && item.isNew == '1') {
        state.RetailerListUser = state.RetailerListUser.filter(x => x.id != item.id);
      }
      return item.id !== id
    });
    state.RetailerList = filteredList;
    setState({ ...state, RetailerList: state.RetailerList, RetailerListUser: state.RetailerListUser });
    if (state.RetailerList.length == 0) {
      setState({ ...state, Retailer: [] });
      // errors.Retailers={message: 'Please select a Brand', type:'required'};  
    }
    setKeyOptions('Retailer', 'KeyRetData');
  }

  const deleteBrand3 = (id) => {
    const filteredList = state.ResellerList.filter((item) => {
      if (item.id == id && item.keypartnerid) {
        let temp = state.deletedReseller;
        temp.push(+item.keypartnerid);
        setState({ ...state, deletedReseller: temp });
      }
      if (item.id == id && item.isNew == '1') {
        state.ResellerListUser = state.ResellerListUser.filter(x => x.id != item.id);
      }
      return item.id !== id
    });
    state.ResellerList = filteredList;
    setState({ ...state, ResellerList: state.ResellerList, ResellerListUser: state.ResellerListUser });
    if (state.ResellerList.length == 0) {
      setState({ ...state, Reseller: [] });
      // errors.Retailers={message: 'Please select a Brand', type:'required'};  
    }
    setKeyOptions('Reseller', 'KeyResData');
  }

  //   useBeforeFirstRender(() => {
  //     (async() =>{  

  //     })();

  // })

  const selectedItem = (gname, check, optionName, optionValue) => {
    let opt = [];
    console.log("check:", check, gname);
    if (check == true) {
      state[optionName].map((item) => {

        if (item.groupname == gname || item.status == true) {
          item.status = check;
          opt.push(item);
        }
      });
    }
    if (check == false) {
      console.log("check:", check);
      state[optionName].map((item) => {
        if (item.groupname == gname && state[optionValue].length > 0)
          item.status = check;
        // if(state.productValue.length==0)
        //     item.status=false;
      });

      opt = state[optionValue].filter((item) => item.groupname != gname);
      opt.map(option => option.status = true);
    }

    state[optionValue] = opt;
    setState({ ...state, [optionValue]: state[optionValue] });
    setState({ ...state, [optionName]: state[optionName] });

    if (optionValue = 'ChannelProfileValue' && state.ChannelProfileValue.length == 0)
      errors.relevantChannelProfiles = true;
    else if (optionValue = 'productValue' && state.productValue.length == 0)
      errors.productCat = true;
  }

  const watchAll = watch(['no_of_retailers', 'no_of_distributors', 'no_of_skus', 'storeCount']);
  //console.log('watchall', watchAll);




  const save = async () => {
    if (userData.roleId == 1 || (userData.roleId != 1 && (previlege == 1 || previlege == 0))) {
      try {
        setLoading(true);

        if (props.ratingId) {
          if (!props.finalData) {
            ToastsStore.error('Please Fill the Company Profile Form');
            props.changeNav(1);
            setLoading(false);
          }
        }

        //for key distributor
        let keyDistributors = [];

        if (isEqual(state.DistributorList.map(x => x.id).sort(), state.testDistributorList.sort())) {
          console.log('no change');
        } else {
          state.DistributorList.map(x => {
            let temp = {};
            if (x.active) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.existchannelid = x.id;
              temp.countryid = x.active.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
            } else if (x.old) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.oldchannelid = x.id;
              temp.countryid = x.old.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
            }
            else if (x.new) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.countryid = x.new.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
            }
            else if (x.channelkeypartnerid) {
              temp.channelkeypartnerid = x?.channelkeypartnerid;
              temp.countryid = x?.regaddresscountry?.countryId;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.companylogo.documentId : null;
            }
            else if (x.isNew != '1') {
              if (x.oldchannelid)
                temp.oldchannelid = x.oldchannelid;
              else if (x.channelId)
                temp.existchannelid = x.channelId;

              temp.partnername = x.companyName;
              temp.image = x.companylogo ? x.companylogo.documentPath : null;
              temp.imageid = x.companylogo ? x.companylogo.documentId : null;
              temp.countryid = x?.regaddresscountry?.countryId;

            }
            if (x.isNew == '1') {

              if (x.documentpath)
                temp.image = x.documentpath;

              temp.partnername = x.name;
              temp.countryid = +x.countryId;
            }
            keyDistributors.push(temp);
          })
          //console.log('fasle');
        }

        //for key retailer

        let keyRetailer = [];

        if (isEqual(state.RetailerList.map(x => x.id).sort(), state.testRetailerList.sort())) {
          console.log('no change');
        } else {
          state.RetailerList.map(x => {
            let temp = {};
            if (x.active) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.existchannelid = x.id;
              temp.countryid = x.active.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
            } else if (x.old) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.oldchannelid = x.id;
              temp.countryid = x.old.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
            }
            else if (x.new) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.countryid = x.new.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
            }
            else if (x.channelkeypartnerid) {
              temp.channelkeypartnerid = x?.channelkeypartnerid;
              temp.partnername = x.name;
              temp.countryid = x?.regaddresscountry?.countryId;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.companylogo.documentId : null;
            }
            else if (x.isNew != '1') {
              if (x.oldchannelid)
                temp.oldchannelid = x.oldchannelid;
              else if (x.channelId)
                temp.existchannelid = x.channelId;

              temp.partnername = x.companyName;
              temp.image = x.companylogo ? x.companylogo.documentPath : null;
              temp.imageid = x.companylogo ? x.companylogo.documentId : null;
              temp.countryid = x?.regaddresscountry?.countryId;

            } if (x.isNew == '1') {
              // temp.countryid = x.countryId;
              if (x.documentpath)
                temp.image = x.documentpath;

              temp.partnername = x.name;
              temp.countryid = +x.countryId;
            }

            keyRetailer.push(temp);
          })
          console.log('fasle');
        }

        //for key reseller
        let keyResellers = [];

        if (isEqual(state.ResellerList.map(x => x.id).sort(), state.testResellerList.sort())) {
          console.log('no change');
        } else {
          state.ResellerList.map(x => {
            let temp = {};
            if (x.active) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.existchannelid = x.id;
              temp.countryid = x.active.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.active.logo.logo_id : null;
            } else if (x.old) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.oldchannelid = x.id;
              temp.countryid = x.old.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.old.logo.logo_id : null;
            }
            else if (x.new) {
              temp.keypartnerid = x.keypartnerid;
              temp.partnername = x.name;
              temp.countryid = x.new.country.country_id;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.new.logo.logo_id : null;
            }
            else if (x.channelkeypartnerid) {
              temp.channelkeypartnerid = x?.channelkeypartnerid;
              temp.countryid = x?.regaddresscountry?.countryId;
              temp.image = x.documentPath ? x.documentPath : null;
              temp.imageid = x.documentPath ? x.companylogo.documentId : null;
            }
            else if (x.isNew != '1') {
              if (x.oldchannelid)
                temp.oldchannelid = x.oldchannelid;
              else if (x.channelId)
                temp.existchannelid = x.channelId;

              temp.partnername = x.companyName;
              temp.image = x.companylogo ? x.companylogo.documentPath : null;
              temp.imageid = x.companylogo ? x.companylogo.documentId : null;
              temp.countryid = x?.regaddresscountry?.countryId;

            }
            if (x.isNew == '1') {

              if (x.documentpath)
                temp.image = x.documentpath;

              temp.partnername = x.name;
              temp.countryid = +x.countryId;
            }
            keyResellers.push(temp);
          })
          //console.log('fasle');
        }

        //deleted retailer
        if (state.deletedRetailer.length) {
          if (state.RetailerList.length) {
            state.RetailerList.map(x => {
              if (x.keypartnerid) {
                let temp = state.deletedRetailer.filter(id => x.keypartnerid != id)
                setState({ ...state, deletedRetailer: temp });
              }
            })
          }
        }

        //deleted distributor
        if (state.deletedDistributor.length) {
          if (state.DistributorList.length) {
            state.DistributorList.map(x => {
              if (x.keypartnerid) {
                let temp = state.deletedDistributor.filter(id => x.keypartnerid != id)
                setState({ ...state, deletedDistributor: temp });
              }
            })
          }
        }

        //deleted Reseller
        if (state.deletedReseller.length) {
          if (state.ResellerList.length) {
            state.ResellerList.map(x => {
              if (x.keypartnerid) {
                let temp = state.deletedReseller.filter(id => x.keypartnerid != id)
                setState({ ...state, deletedReseller: temp });
              }
            })
          }
        }

        let data = {};
        //key retailer value
        if (keyRetailer.length) {
          data = Object.assign(data, { channelKeyRetailer: keyRetailer })
        }

        //key distributor value
        if (keyDistributors.length) {
          data = Object.assign(data, { channelKeyDistributor: keyDistributors })
        }

        //delete retailer value
        if (state.deletedRetailer.length) {
          data = Object.assign(data, { deletedkeyretailer: state.deletedRetailer })
        }

        //delete distributor value
        if (state.deletedDistributor.length) {
          data = Object.assign(data, { deletedkeydistributor: state.deletedDistributor });
        }

        //deleted reseller
        if (state.deletedReseller.length) {
          data = Object.assign(data, { deletedkeyreseller: state.deletedReseller });
        }

        if (keyResellers.length) {
          data = Object.assign(data, { channelKeyReseller: keyResellers })
        }


        //setting profiles (relevant retailer and relevant reseller)
        let customerProfiles = {};

        let tmp = [].concat(state.ChannelProfileValue.map(x => { return { profileTypeId: +x.childid, profileTypeClassificationId: +x.profiletypeclassificationid } }));
        let tmp1 = [].concat(state.ResellerProfileValue.map(x => { return { profileTypeId: +x.childid, profileTypeClassificationId: +x.profiletypeclassificationid } }));
        customerProfiles = [...tmp, ...tmp1];




        let finalData = {
          ...data,
          keyretailers: state.RetailerDesc,
          keyresellers: state.ResellerDesc,
          keydistributors: state.distributorDesc,
          customerProfiles: customerProfiles,
          no_of_retailers: state.no_of_retailers,
          no_of_resellers: state.no_of_resellers,
          no_of_distributors: state.no_of_distributors,
          isCompanyPublic: state.isCompanyPublic
          // is_french_tech: state.frenchTech=="Yes" ? '1' : '0',
        }

        let result = null;

        if (props.ratingId) {

          let data = {
            ...props.finalData,
            channels: finalData
          }

          await commonService.postService(data, '/setting/update_company_profile').then(res => {
            ToastsStore.success('Saved Successfully!');

            setLoading(false);

            if (props.ratingId) {
              history.push('/admin/dashboard');
            }
          })


        } else {
          result = await commonService.postService(finalData, '/setting/updateChannel').catch(err => {
            ToastsStore.error('Something Went Wrong!');
            commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/setting/updateTradeInfo' }, '/error')
            setLoading(false)
          });
        }


        //  if(result){
        //    setLoading(false);
        //  }
        if (result) {
          ToastsStore.success('Saved Successfully!')
          await getTradeInfo();
        }
      }
      catch (err) {
        ToastsStore.error('Something Went Wrong! while saving tradeInformation');
        console.log('error123', err);
        setLoading(false);
        commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: 'updateTradeInfo' }, '/error');
      }
    }
    else {
      modalPopValue.message = 'You are restricted to Modify and Save Company Info';
      modalPopValue.second_message = 'PLEASE CONTACT ACCOUNT OWNER'
      modalPopValue.title = 'Access Denied';
      setModalPopValue({ ...modalPopValue });
      setModalPopup(true);
    }

  }

  const handleChange = input => e => {
    console.log("add", e.target.value);
    // setState({...state, [input] : e.target.value});

    if (input === 'startUpType1' || input === 'startUpType2' || input === 'startUpType3') {
      state[input] = !state[input];
      state.startUpType = state.startUpType1 ? 'set' : state.startUpType2 ? 'set' : state.startUpType3 ? 'set' : '';
      setState({ ...state, startUpType: state.startUpType, [input]: state[input] });
    }
    else if (input === 'locationType' && state.locationType != e.target.value) {

      // state.EMEA=false;                
      // state.APAC=false;
      // state.AMERICAS=false;

      // if(e.target.value==='Target Locations')
      // {
      //   state.sellingZones.tzone.map(x => {
      //     if(x.zone==='EMEA')
      //         state.EMEA=true;                
      //     if(x.zone==='APAC')
      //         state.APAC=true;
      //     if(x.zone==='AMERICAS')
      //         state.AMERICAS=true;
      //   })
      // }

      // if(e.target.value==='Selling Locations')
      // {
      //   state.sellingZones.szone.map(x => {
      //     if(x.zone==='EMEA')
      //         state.EMEA1=true;                
      //     if(x.zone==='APAC')
      //         state.APAC1=true;
      //     if(x.zone==='AMERICAS')
      //         state.AMERICAS1=true;
      //   })
      // }

      errors.target = false;
      errors.selling = false;
      setState({ ...state, [input]: e.target.value, title: e.target.value === 'Target Locations' ? 'TARGET' : 'SELLING' });
    } else if (input === 'AMERICAS' || input === 'EMEA' || input === 'APAC' || input === 'AMERICAS1' || input === 'EMEA1' || input === 'APAC1') {
      setState({ ...state, [input]: !state[input] });
    }
    else {
      setState({ ...state, [input]: e.target.value });
    }
    // if(input=='areYouStartUp'&&value=='0'){
    //   state.maturitylevel=null;
    //   setState({...state, maturitylevel:state.maturitylevel});
    // }
    // else    
  }

  const handleChangeSelect = (input, value) => {



    if (input == 'productFamily') {
      state.productFamilies.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });     
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.productValue = value
      setState({ ...state, productFamilies: state.productFamilies });
      setState({ ...state, productValue: state.productValue });

      // state.productValue= value
      // setState({...state, productValue:state.productValue});
      if (state.productValue.length == 0)
        errors.productValue = true;

      let Group = _.uniq(state.productValue.map(x => x.groupid));
      state.groupCount = Group.length;
      setState({ ...state, groupCount: state.groupCount });



    }
    else if (input == 'relevantChannelProfile') {
      state.ChannelProfileOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });      
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.ChannelProfileValue = value
      setState({ ...state, ChannelProfileOption: state.ChannelProfileOption });
      setState({ ...state, ChannelProfileValue: state.ChannelProfileValue });

      if (state.ChannelProfileValue.length == 0)
        errors.relevantChannelProfiles = true;
      // state.ChannelProfileValue= value
      // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    else if (input == 'relevantResellerProfile') {
      state.ResellerProfileOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });     
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.ResellerProfileValue = value
      setState({ ...state, ResellerProfileOption: state.ResellerProfileOption });
      setState({ ...state, ResellerProfileValue: state.ResellerProfileValue });

      if (state.ResellerProfileValue.length == 0)
        errors.relevantResellerProfiles = true;
      // state.ChannelProfileValue= value
      // setState({...state, ChannelProfileValue:state.ChannelProfileValue});
    }
    else if (input == 'relevantRetailerProfile') {
      state.ChannelProfileValue = value
      setState({ ...state, ChannelProfileValue: state.ChannelProfileValue });
    }
    // else if(input=='listOfServiceValue'){
    //   state.listOfServiceValue= value
    //   setState({...state, listOfServiceValue:state.listOfServiceValue});
    // }   


    else if (input == 'brand' || input == 'brands' || input == 'Distributor' || input == 'Retailer' || input == 'Reseller') {
      if (input == 'brands')
        input = 'brand';
      if (value.name === 'Add Brand' && value.id === 0) {
        setBrandName('');
        opneAdd(true);
        return <></>;
      }
      if (value.hasOwnProperty('isNew')) {
        state[input] = value;
      } else {
        value.isNew = '0';
        state[input] = value;
      }

      setState({ ...state, [input]: state[input] });
      let temp = state[input];
      temp = state[input + 'List'].filter(x => x.id == temp.id);
      if (temp.length) {
        console.log('already entered');
      } else {
        console.log('no entry')
        if (value.isNew == '1')
          state[input + 'ListUser'].push(state[input]);
        state[input + 'List'].push(state[input]);
        state[input + 'Options'] = state[input + 'Options'].filter(x => x.id != value.id);
        setState({ ...state, [input + 'List']: state[input + 'List'], [input + 'ListUser']: state[input + 'ListUser'] });
      }

      state[input] = [];
      setState({ ...state });

      console.log("brandList:", state[input + 'list']);
    }
    else if (input == 'sellingCountries') {
      state.sellingCountriesOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   // if(v.childname!=item.childname)
          //   //   item.status=false;                 
          //    if(v.childname==item.childname)            
          //     item.status=true;                       

          // });     
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      });

      value.map(option => option.status = true);
      state.sellingCountries = value
      setState({ ...state, sellingCountries: state.sellingCountriesOption });
      setState({ ...state, sellingCountries: state.sellingCountries });

      if (state.sellingCountries.length == 0)
        errors.selling = true;
    }
    else if (input == 'targetCountries') {
      state.targetCountries = state.targetCountriesOption.map((item) => {
        if (value.length == 0)
          item.status = false;
        else {
          // value.map(v => {          
          //   if(v.childId==item.childId)
          //   {
          //     item.status=true; 
          //   }
          //   // if(v.childId==item.childId || item.status==true)            
          //   // {
          //   //   item.status=true;                       
          //   // }
          // });  
          if (value.filter(e => e.childname == item.childname).length)
            item.status = true;
          else
            item.status = false;
        }
      })

      // });
      value.map(option => option.status = true);
      state.targetCountries = value
      setState({ ...state });//, targetCountriesOption:state.targetCountriesOption});
      // setState({...state, targetCountries:state.targetCountries});
      if (state.targetCountries.length == 0)
        errors.target = true;
    }
    else {
      if (input == "speciality" && value.length <= 3)
        setState({ ...state, [input]: value });
      else if (input != "speciality")
        setState({ ...state, [input]: value });
    }

  }



  const opneAdd = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    opneAddnew(true);
  }
  const opneAdd1 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenDist(true);
  }

  const opneAdd2 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenRetailer(true);
  }

  const opneAdd3 = async (input, value) => {
    console.log('opneadd', opneadd);
    await setBrandName(input);
    setOpenReseller(true);
  }
  const closeModalPopup = () => {
    setBrandName('');
    opneAddnew(false);
    setOpenRetailer(false);
    setOpenDist(false);
    setOpenReseller(false);
  }

  console.log('satet', state);
  //console.log('watchAll', watchAll)

  const onSubmit = data => {
    if (data) {
      console.log("data:", data);
    }
  }
  console.log('errrors', errors);

  const closePopup = () => {
    setModalPopup(false);
  };

  const confirmPopup = (res) => {
    setModalPopup(false);
  }


  return (
    <div className="main-content">
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
      <Dialog open={isModalPopup} value={modalPopValue} handleSave={confirmPopup} handleClose={closePopup} />
      {loading ? <Spinner /> : (

        <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
          <form noValidate onSubmit={handleSubmit(save)}>
            {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><ModalSpinner /></div>}
            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="typeId" ref={register} name="typeId" value={typeId} control={control} />

            <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="businessType" ref={register} name="businessType" value={businessType} control={control} />

            {/* <Card>
              <CardBody>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12  row ">
                    <div className="form-group text-center text-sm mb-0">
                      <h4>Your company profile visibility on channelhub Public web sites</h4>
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="isCompanyPublic" name="isCompanyPublic" value={+state.isCompanyPublic} />
                      <FormRadio
                        name="startup"
                        className="text-sm mb-0"
                        options={radioGroupOptions}
                        control={control}
                        row
                        value={state.isCompanyPublic}
                        onChange={handleChange('isCompanyPublic')}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card> */}





            <Card>
              <CardHeader>
                <h3 className="text-center">Distribution Channels</h3>
                <p className="text-center"></p>
              </CardHeader>

              <CardBody>
                <div className="row">
                  <div className="col-lg-12 ">
                    {/* Key Distributors */}

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <h4 >Current number of Distributors </h4>
                      <FormInput className="comp-name mt-3" id="no_of_distributors" name="no_of_distributors" label="No. of Distributors *" type="number" variant="outlined" value={watchAll.no_of_distributors}
                        error={errors.no_of_distributors}
                        defaultValue={state.no_of_distributors}
                        onChange={handleChange('no_of_distributors')}
                        inputRef={register}
                      />
                    </div>
                    <br />

                    <div className="row">
                      <div className="col-lg-12 ">
                        <h4 >Key Distributors </h4>
                        <div className="form-group mt-3">

                          {state.DistributorList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand1} brandList={state.DistributorList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                          {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="DistributorList" name="DistributorList" ref={register} value={state.DistributorList[0] ? 'data' : ''} />  */}
                          {/* Autocomplete with image */}
                          <AddFieldWithPopUpAutocomplete
                            limitTags={2}
                            name="Distributor"
                            id="Distributor"
                            options={state.DistributorOptions}//{state.DistributorProfileOption}
                            defaultValue={state.Distributor}
                            value={state.Distributor}
                            getOptionLabel={option => option.name}
                            renderOption={option => (
                              <>
                                <div style={{
                                  display: '-webkit-inline-flex',
                                  padding: '1px 6px',
                                  width: '75px',
                                  margin: '10px 5px 0 0',
                                  float: 'left',
                                  border: '2px solid #e7e7e7',
                                  borderRadius: '5px',
                                  height: '38px'
                                }}>

                                  <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                                    maxWidth: '63px', backgroundColor: '#fff',
                                    maxHeight: '34px',
                                    height: 'auto',
                                    width: 'auto',
                                    margin: '0 auto',
                                    cursor: 'pointer'
                                  }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                                <span>
                                  {option.name}
                                </span>
                              </>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" label="Key Distributors" placeholder="Key Distributors" />
                            )}
                            label="Key Distributors"
                            placeholder="Key Distributors"
                            input="Distributor"
                            control={control}
                            register={register}
                            // errMessage={'Please Select  Key Distributors'}
                            // error={errors.Distributor&&!state.DistributorList[0]? errors.Distributor : (errors.DistributorList && !state.DistributorList[0]) ? true : false}
                            handleChangeSelect={handleChangeSelect}
                            opneAdd={opneAdd1}
                          />
                          {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openDist} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={3} input="Distributor" countryList={state.countryList} userList={state.DistributorListUser} />}

                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <h4>Others Key Distributors Description</h4>
                        <div className="form-group mt-3">
                          <form>
                            <textarea

                              class="buytext2"
                              id="exampleFormControlTextarea1"
                              onChange={handleChange('distributorDesc')}
                              rows="3"
                              value={state.distributorDesc}
                              aria-label="empty textarea"
                              placeholder="Others Key Distributors Description">
                            </textarea>
                          </form>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </CardBody>
            </Card>


            {/* <Card>
              <CardHeader>
                <h3 className="text-center">Relevant retail/reseller profiles for your products</h3>
                <p className="text-center"></p>
              </CardHeader>
              <CardBody>
        


              </CardBody>
            </Card> */}




            {(businessType == 1 || businessType == 3) &&
              <Card>
                <CardHeader>
                  <h3 className="text-center">Retail channels</h3>
                  <p className="text-center"></p>
                </CardHeader>
                <CardBody>
                <div className="row">
                  <div className="col-lg-12 ">
                    <h4 style={{padding: "0 15px"}}>Profile of your Retailers  *</h4>
                    <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantChannelProfiles" name="relevantChannelProfiles" ref={register} value={state.ChannelProfileValue.length ? state.ChannelProfileValue[0].childname : ''} />
                    <div className="form-group" style={{padding: "0 15px"}}>
                      <FormAutocompleteGrouping
                        id="relevantProfiles"
                        name="relevantProfiles"
                        label="Channel Profiles * "
                        multiple={true}
                        Options={state.ChannelProfileOption}
                        placeholder="Channel Profiles"
                        input='relevantChannelProfile'
                        errMessage={'Please Select Channel Profile'}
                        error={!errors.relevantChannelProfiles || state.ChannelProfileValue.length ? false : true}
                        value={state.ChannelProfileValue}

                        optionName='ChannelProfileOption'
                        optionValue='ChannelProfileValue'
                        defaultValue={state.ChannelProfileValue}
                        selectedItem={selectedItem}

                        handleChangeSelect={handleChangeSelect}
                        control={control}
                        register={register}
                      //defaultValue={state.ChannelProfileValue}
                      />

                    </div>
                  </div>
                  </div>



                  <div className="row">
                    <div className="col-lg-12 ">

                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h4 >Current number of retail partners </h4>
                        <FormInput className="comp-name mt-3" name="no_of_retailers" id="no_of_retailers" label="No. of Retailers *" type="number" variant="outlined" value={watchAll.no_of_retailers}
                          onChange={handleChange('no_of_retailers')}
                          defaultValue={state.no_of_retailers}
                          error={errors.no_of_retailers}
                          inputRef={register}
                        />
                      </div>
                      <br />

                      <div className="row">
                      <div className="col-lg-12">
                      <h4 >Key Retailers </h4>
                      <div className="form-group mt-3">

                        {state.RetailerList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand2} brandList={state.RetailerList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                        {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="retailerList" name="retailerList" ref={register} value={state.RetailerList[0] ? 'data' : ''} />  */}
                        {/* Autocomplete with image */}
                        <AddFieldWithPopUpAutocomplete
                          limitTags={2}
                          name="Retailers"
                          id="Retailers"
                          options={state.RetailerOptions} //{state.RetailerProfileOption}
                          defaultValue={state.Retailer}
                          value={state.Retailer}
                          getOptionLabel={option => option.name}
                          renderOption={option => (
                            <>
                              <div style={{
                                display: '-webkit-inline-flex',
                                padding: '1px 6px',
                                width: '75px',
                                margin: '10px 5px 0 0',
                                float: 'left',
                                border: '2px solid #e7e7e7',
                                borderRadius: '5px',
                                height: '38px'
                              }}>

                                <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                                  maxWidth: '63px', backgroundColor: '#fff',
                                  maxHeight: '34px',
                                  height: 'auto',
                                  width: 'auto',
                                  margin: '0 auto',
                                  cursor: 'pointer'
                                }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                              <span>
                                {option.name}
                              </span>
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Retailers" placeholder="Retailers" />
                          )}
                          label="Key Retailers"
                          placeholder="Key Retailers"
                          input="Retailer"
                          control={control}
                          register={register}
                          // errMessage={'Please Select a Key Retailers'}
                          // error={errors.Retailers&&!state.RetailerList[0]? errors.Retailers : (errors.retailerList && !state.RetailerList[0]) ? true : false}
                          handleChangeSelect={handleChangeSelect}
                          opneAdd={opneAdd2}
                        />
                        {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openRetailer} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={4} input="Retailer" countryList={state.countryList} userList={state.RetailerListUser} />}

                      </div>
                      </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <h4>Others Key Retailers Description</h4>
                          <div className="form-group mt-3">
                            <form>
                              <textarea
                                class="qtytext buytext2"
                                aria-label="maximum height"
                                id="exampleFormControlTextarea1"
                                onChange={handleChange('RetailerDesc')}
                                rows="3"
                                value={state.RetailerDesc}
                                aria-label="empty textarea"
                                placeholder="Others Key Retailers Description">
                              </textarea>
                            </form>
                          </div>
                        </div>
                      </div>





                    </div>
                  </div>





                </CardBody>
              </Card>
            }



            {(businessType == 2 || businessType == 3) &&
              <Card>

                <CardBody>




                  <div className="row">
                    <div className="col-lg-12 ">


                      <CardHeader>
                        <h3 className="text-center">Reseller Channels </h3>
                        <p className="text-center"></p>
                      </CardHeader>

                      <div className="col-lg-12 mt-4 ">
                        <h4 >Profile of your Resellers  *</h4>
                        <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="relevantResellerProfiles" name="relevantResellerProfiles" ref={register} value={state.ResellerProfileValue.length ? state.ResellerProfileValue[0].childname : ''} />
                        <div className="form-group">
                          <FormAutocompleteGrouping
                            id="resellerProfiles"
                            name="resellerProfiles"
                            label="Reseller Profiles * "
                            multiple={true}
                            Options={state.ResellerProfileOption}
                            placeholder="Channel Profiles"
                            input='relevantResellerProfile'
                            errMessage={'Please Select Channel Profile'}
                            error={!errors.relevantResellerProfiles || state.ResellerProfileValue.length ? false : true}
                            value={state.ResellerProfileValue}

                            optionName='ResellerProfileOption'
                            optionValue='ResellerProfileValue'
                            defaultValue={state.ResellerProfileValue}
                            selectedItem={selectedItem}

                            handleChangeSelect={handleChangeSelect}
                            control={control}
                            register={register}
                          //defaultValue={state.ChannelProfileValue}
                          />

                        </div>
                      </div>




                      {/* Add Key Reseller */}


                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <h4 >Current number of Reseller partners </h4>
                        <FormInput className="comp-name mt-3" name="no_of_resellers" id="no_of_resellers" label="No. of Resellers *" type="number" variant="outlined" value={watchAll.no_of_resellers}
                          onChange={handleChange('no_of_resellers')}
                          defaultValue={state.no_of_resellers}
                          error={errors.no_of_resellers}
                          inputRef={register}
                        />
                      </div>
                      <br />
                      <div className="row">
                      <div className="col-lg-12">
                      <h4 >Key Resellers </h4>
                      <div className="form-group mt-3">

                        {state.ResellerList[0] && <BrandCard name="BMW" deleteBrand={deleteBrand3} brandList={state.ResellerList} img={"https://d35w8j22j5uly8.cloudfront.net/document/brand/KVw8QS0n_1545236009520_1545236013342.png"} />}

                        {/* <input type="text" style={{position:"absolute",left:"-10000px",top:'auto',width:'1px',height:'1px',overflow:'hidden'}} id="retailerList" name="retailerList" ref={register} value={state.RetailerList[0] ? 'data' : ''} />  */}
                        {/* Autocomplete with image */}
                        <AddFieldWithPopUpAutocomplete
                          limitTags={2}
                          name="Resellers"
                          id="Resellers"
                          options={state.ResellerOptions} //{state.RetailerProfileOption}
                          defaultValue={state.Reseller}
                          value={state.Reseller}
                          getOptionLabel={option => option.name}
                          renderOption={option => (
                            <>
                              <div style={{
                                display: '-webkit-inline-flex',
                                padding: '1px 6px',
                                width: '75px',
                                margin: '10px 5px 0 0',
                                float: 'left',
                                border: '2px solid #e7e7e7',
                                borderRadius: '5px',
                                height: '38px'
                              }}>

                                <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} style={{
                                  maxWidth: '63px', backgroundColor: '#fff',
                                  maxHeight: '34px',
                                  height: 'auto',
                                  width: 'auto',
                                  margin: '0 auto',
                                  cursor: 'pointer'
                                }} src={option.documentpath ? option.documentpath : process.env.REACT_APP_CDN_URL + "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"} />    </div>
                              <span>
                                {option.name}
                              </span>
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Resellers" placeholder="Resellers" />
                          )}
                          label="Key Resellers"
                          placeholder="Key Resellers"
                          input="Reseller"
                          control={control}
                          register={register}
                          // errMessage={'Please Select a Key Retailers'}
                          // error={errors.Retailers&&!state.RetailerList[0]? errors.Retailers : (errors.retailerList && !state.RetailerList[0]) ? true : false}
                          handleChangeSelect={handleChangeSelect}
                          opneAdd={opneAdd3}
                        />
                        {brandName && <AddKeyPartner aws_folderName="brandlogo" opneadd={openReseller} closeModalPopup={closeModalPopup} brandName={brandName} handleChangeSelect={handleChangeSelect} partner_type={5} input="Reseller" countryList={state.countryList} userList={state.ResellerListUser} />}

                      </div>
                      </div>
                      </div>



                    </div>
                  </div>


                  <div className="row">
                    <div className="col-lg-12">
                      <h4 style={{padding: "0 15px"}}>Others Key Resellers Description</h4>
                      <div className="form-group mt-3" style={{padding: "0 15px"}}>
                        <form>
                          <textarea
                            class="qtytext buytext2"
                            id="exampleFormControlTextarea1"
                            onChange={handleChange('ResellerDesc')}
                            rows="3"
                            value={state.ResellerDesc}
                            aria-label="empty textarea"
                            placeholder="Others Key Resellers Description">
                          </textarea>
                        </form>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            }
            <div className="text-center mt-3">

              {props.ratingId && <button className="btn btn-primary" onClick={e => { e.preventDefault(); props.changeNav(1); }} >Back</button>}
              <button type="submit" className="btn btn-primary" >Save</button>

            </div>
          </form>

        </Container>
      )}
    </div>


  );
};

const top100Films = [
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
  { name: 'The Shawshank Redemption', id: 1994 },
  { name: 'The Godfather', id: 1972 },
  { name: 'The Godfather: Part II', id: 1974 },
  { name: 'The Dark Knight', id: 2008 },
]

const startUp = [
  { label: 'Prototype', id: 1, value: 'PROTOTYPE' },
  { label: 'Shipping to first backers', id: 2, value: 'S_BACKER' },
  { label: 'Shipping in volume', id: 3, value: 'S_VOLUME' },
];


const radioGroupOptions = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },

];

export default YourChannel;
