import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Card, CardBody, Row, Col, Container, UncontrolledTooltip, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { commonService } from '_services/common.service';
import { history } from '../../../../_helpers';
import axios from 'axios';
const ProductSlider = ({products}) => {
  // const [catslide, setcatSlide] = useState(0);
  const [slide, setSlide] = useState(0);
console.log("prd", products);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    afterChange: current => setSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // const { products } = props;
  // useEffect(() => {
  //   getCategoryDetails();
  // }, []);

  // const getCategoryDetails = async () => {

    // if (products) {
    //   let data = products;
    //   if (data.length > 0) {
    //     let cat = [];
    //     let res = [];
    //     data.map((x, i) => {
    //       res.push(x);
    //       if ((i + 1) % 4 === 0 || data.length === i + 1) {
    //         cat.push(res);
    //         res = [];
    //       }
    //     });
    //     catList = cat;
    //   } else
    //     catList = [];
    //   setCatList(catList);


  //   if (products) {
  //     let data = products;
  //     if (data.length > 0) {
  //       let cat = [];
  //       let res = [];
  //       data.map((x, i) => {
  //         console.log("aaaaaa", x);
  //         res.push(x);
  //         // console.log("ggggggggg", res.push(x));
  //         if ( data.length > 1) {
  //           cat.push(res);
  //           console.log("tesssss", cat.push(res));
  //           res = [];
  //         }
  //       });
  //       catList = cat;
  //     } 
  //     // else
  //     //   catList = [];
  //     setCatList(catList);
  //   }
  // };
  const productDetailView = (id,externalURL) => {
   // let url = "/productDetailView/" + id;
   // const win = window.open(url);
   //console.log(' testing  source ===== ', externalURL);
   const win = window.open(externalURL);
    win.focus();
  }
  return (
    <div>
      <Card className="mb-0 p-3">
        <Slider {...settings}>
            {/* <div className='prod-slide bgBack p-4'> */}

              {/* <div className="d-flex bg-white res-prod"> */}
              { products.map(item => (
                  <div className="wid33">
                    <div className="ListContainerForSlider ">
                      <div onClick={() => { productDetailView(item.productSKU,item.externalURL) }}>
                        {/* <div to="/productList"> */}
                        <div className='prod-list prodlistres'>
                          <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }} src={item?.documentUrl ? item.documentUrl : "https://d35w8j22j5uly8.cloudfront.net/document/product/packing/0fz2iZqt_1614278863657_1614278899685.png"} />
                        </div>
                        <h2 style={{ overflowWrap:'anywhere',marginLeft:"1rem" }} className="mt-2 d-inline-block"> {item?.product} </h2>
                        {/* <p className="d-inline-block ml-2">({x?.total})</p> */}
                      </div>
                    </div>
                  </div>
                ))}
              {/* </div> */}
            {/* </div> */}
        </Slider>
      </Card>
    </div>
  );
};

export default ProductSlider;